import { memo } from "react";
import { DateTime } from "luxon";
import { getDeviceInfoBySku, getTextWidth } from "../../../helpers/helpers";
import RolesEnum, { getRoleLabel } from "common/enums/RolesEnum";
import { getDataFieldColumn } from "./dataFieldColumn";
import IndeterminateCheckbox from "../IndeterminateCheckbox";
import { TableColumn } from "../TableTypes";
import { createColumnHelper } from "@tanstack/react-table";
import { DefaultTableCell } from "../../../styling/StyleComponents";
import CalendarVisitsResponseType from "common/types/Calendaring/CalendarVisitsResponseType";
import { minusBusinessDays } from "common/helpers/BusinessDaysHelper/BusinessDaysHelper";

const columnHelper = createColumnHelper();

// this is used for provider and intake nurse encounters
export function isSameDayEditDeleteAllowed(createdAtDate: string) {
  const boundaryUTC = DateTime.fromISO(createdAtDate)
    .endOf("day")
    .toUTC()
    .plus({ hours: 3 });

  const now = Date.now();

  if (isNaN(boundaryUTC.toMillis())) {
    return false;
  }

  if (now > boundaryUTC.toMillis()) {
    return false;
  } else {
    return true;
  }
}

// this is used for tn and in encounters
export function matchesTenDaySameMonthEncounterRestriction(
  createdAtDate: string
) {
  const dateOfEncounter = DateTime.fromISO(createdAtDate);
  if (!dateOfEncounter.isValid) {
    return false;
  }

  const now = DateTime.now();

  const startOfMonth = now.startOf("month").startOf("day");
  if (dateOfEncounter < startOfMonth) {
    return false;
  }

  const tenBusinessDaysBeforeNow = minusBusinessDays(now, 10).startOf("day");

  if (dateOfEncounter > tenBusinessDaysBeforeNow && dateOfEncounter <= now) {
    return true;
  } else {
    return false;
  }
}

export function isEditDeleteAllowed(createdAtDate: string) {
  const boundaryUTC = DateTime.fromISO(createdAtDate)
    .endOf("day")
    .toUTC()
    .plus({ hours: 3 });

  const now = Date.now();

  if (isNaN(boundaryUTC.toMillis())) {
    return false;
  }

  if (now > boundaryUTC.toMillis()) {
    return false;
  } else {
    return true;
  }
}

export function getCalendarVisitsCreatedModifiedSize(
  item: CalendarVisitsResponseType
) {
  const createdRoles = item?.created_by_staff?.roles;
  const modifiedRoles = item?.modified_by_staff?.roles;
  const createdRoleLabels = [];
  const modifiedRoleLabels = [];

  createdRoles?.forEach((role) => {
    createdRoleLabels.push(getRoleLabel(role));
  });

  modifiedRoles?.forEach((role) => {
    modifiedRoleLabels.push(getRoleLabel(role));
  });

  const createdRolesString = createdRoleLabels?.slice(0, 3).join(", ");
  const modifiedRolesString = modifiedRoleLabels?.slice(0, 3).join(", ");

  const textLinesCreated = Math.max(
    getTextWidth(createdRolesString, undefined) / 220
  );
  const textLinesModified = Math.max(
    getTextWidth(modifiedRolesString, undefined) / 220
  );

  const createdBy = item?.created_by;
  const modifiedBy = item?.modified_by;
  const createdDate = item?.created_at;
  const modifiedDate = item?.modified_at;

  if (item?.created_at !== item?.modified_at) {
    return (
      // created line of text
      36 +
      //  gap in flexbox between elements
      4 +
      18 * textLinesCreated // 18px for one line for roles
    );
  }
  return (
    // created and modified lines of text
    36 * 2 +
    //  gap in flexbox between elements
    3 * 4 +
    18 * (textLinesCreated > 1 ? 2 : 1) + // 18px for one line for roles
    18 * (textLinesModified > 1 ? 2 : 1) // 18px for one line for roles
  );
}

function getCheckboxColumn(name: string, tableProps: any, columnProps?: any) {
  switch (name) {
    case "checkboxColumn":
      return {
        id: "checkboxColumn",
        accessor: "",
        func: "accessor",
        size: 30,
        header: ({ table }: any) => {
          const { enableMultiRowSelection } = tableProps;
          return (
            <>
              {enableMultiRowSelection ? (
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler()
                  }}
                />
              ) : null}
            </>
          );
        },
        cell: ({ row }: any) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler()
              }}
            />
          </div>
        ),
        ...columnProps
      };
    default:
      return null;
  }
}

function getDataListColumn(
  column: TableColumn,
  tableProps: any,
  columnProps?: any
) {
  const { name, filterEnabled, size } = column;
  switch (name) {
    case "orderItems":
      return {
        header: "Items",
        id: "orderItems",
        accessor: "order.items",
        func: "accessor",
        size: 250,
        cell: memo(({ getValue }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell>
              {value?.map((item: any, index: number) => {
                const { quantity, sku, device_id } = item || {};
                const id = `${index}-${sku}-${device_id}`;
                const deviceInfo = getDeviceInfoBySku(sku);
                const { description } = deviceInfo || {};
                if (description && quantity) {
                  return <div key={id}>{`${description} x${quantity}`}</div>;
                } else {
                  return <div key={id}>{`${sku} x${quantity}`}</div>;
                }
              })}
            </DefaultTableCell>
          );
        }),
        ...columnProps
      };
    case "deviceOrdersItems":
      return {
        header: "Items",
        id: "deviceOrdersItems",
        accessor: "order.items",
        func: "accessor",
        size: 250,
        cell: memo(({ getValue }: any) => {
          const value = getValue();

          return (
            <DefaultTableCell>
              {value?.map((item: any, index: number) => {
                const { sku, device_id, description } = item || {};
                const id = `${index}-${sku}-${device_id}`;
                return <div key={id}>{`${description}`}</div>;
              })}
            </DefaultTableCell>
          );
        })
      };

    case "refillOrdersItems":
      return {
        header: "Items",
        id: "refillOrdersItems",
        accessor: "order.items",
        func: "accessor",
        size: 250,
        cell: memo(({ getValue }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell>
              {value?.map((item: any, index: number) => {
                const { quantity, sku, device_id, sku_type } = item || {};
                const id = `${index}-${sku}-${device_id}`;
                const deviceInfo = getDeviceInfoBySku(sku);
                const description = deviceInfo?.description || sku;
                const showQuantity = sku_type === "REFILL" && quantity;
                if (showQuantity) {
                  return <div key={id}>{`${description} x${quantity}`}</div>;
                } else {
                  return <div key={id}>{`${description}`}</div>;
                }
              })}
            </DefaultTableCell>
          );
        })
      };
    case "roles":
      return {
        id: "roles",
        accessor: "user.roles",
        func: "accessor",
        size,
        cell: memo(({ getValue, column: { id } }: any) => {
          const value = getValue();
          return (
            <>
              {value?.map((role: string, index: number) => {
                return (
                  <DefaultTableCell
                    style={{
                      overflowWrap: "anywhere"
                    }}
                    key={`${id}${index}`}
                  >
                    {getRoleLabel(role as RolesEnum)}
                  </DefaultTableCell>
                );
              })}
            </>
          );
        }),
        header: "Roles",
        sortDescFirst: true,
        enableColumnFilter: filterEnabled === true,
        enableSortingRemoval: false,
        ...columnProps
      };
    default:
      return null;
  }
}

export function createColumnObj(column: TableColumn, tableProps: any) {
  const { type, name, ...columnProps } = column;
  switch (type) {
    case "checkbox":
      return getCheckboxColumn(name, tableProps, columnProps);
    case "dataList":
      return getDataListColumn(column, tableProps, columnProps);
    default:
      return getDataFieldColumn(name, tableProps, columnProps);
  }
}

type columnConfigType = {
  accessor?: string;
  filterFn?: string;
  func?: string;
  cell?: any;
  size?: number;
  // TODO not sure how to define the header type for the "patientStatus" case in table helpers
  header?: string | any;
  sortDescFirst?: boolean;
  enableSortingRemoval?: boolean;
  footer?: any;
  id?: string;
} | null;

/**
 *
 * @param columns Array of column objects
 * @returns
 */

export function getColumns(columns: TableColumn[], tableProps: any) {
  return columns.map((column: TableColumn) => {
    // @ts-ignore
    const col: columnConfigType = createColumnObj(column, tableProps);
    if (col) {
      const { func, accessor, ...rest } = col || {};
      // example callFunc: columnHelper.accessor
      const callFunc = columnHelper[func as keyof typeof columnHelper];
      // @ts-ignore
      return callFunc(accessor, rest);
    }
    return null;
  });
}
