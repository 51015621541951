import { DateTime } from "luxon";
import {
  checkIdValid,
  formatName,
  getNameOrUsername,
  isFalsy,
  sanitizeId
} from "common/helpers/helpers";
import {
  useAddActionsToTaskMutation,
  useGetTasksCountQuery,
  useGetTasksQuery
} from "common/services/TaskingService";
import { CustomTooltip } from "../../styling/StyleComponents";
import { Box, Chip, Typography, styled } from "@mui/material";
import ErrorComponent from "../../components/ErrorComponent";
import Skeleton from "react-loading-skeleton";
import {
  CircleOutlinedContainer,
  Flexbox,
  StyledInternalLink,
  StyledOutlineLoadingButton
} from "../../styling/NewStyleComponents";
import { UpdateCallOutcomeForm } from "../../helpers/components/Forms/UpdateCallOutcomeForm/UpdateCallOutcomeForm";
import { useEffect, useMemo, useState } from "react";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import {
  StyledApproveFileIcon,
  StyledDangerIcon,
  TasksContainer,
  TaskContainer,
  getTaskColor,
  getTaskText,
  hasReadTask,
  TaskContactAttemptsContainer
} from "./helpers";
import { TaskParams, TaskType } from "common/types/TaskType";
import TaskActionType from "common/types/TaskAction";
import { useNavigate } from "react-router-dom";
import { callPatientModalSelector } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import RolesEnum, {
  canSeeTasks,
  canSeeBillingTasks,
  hasNurseRole,
  canSeeCareFlow
} from "common/enums/RolesEnum";
import AccordionComponent from "../../components/Accordion/Accordion";
import replace from "lodash.replace";
import { MEMBERS_PATH } from "../../routes/RoutePaths";
import useSanitizedParams from "../../hooks/useSanitizedParams";

import { InfoOutlined } from "@mui/icons-material";
import { gray } from "common/styling/colors";
import TaskTypeEnum from "common/enums/TaskTypeEnum";
import DropdownDateRangePicker from "../../components/DropdownDateRangePicker";
import TaskActionTypeEnum from "common/enums/TaskActionTypeEnum";
import UserTypeInner from "common/types/UserTypeInner";
import StartCareFlow from "../../components/ScheduleToday/StartCareFlow";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";

const Fill = styled("div")`
  display: flex;
  flex: 1;
`;

function getTasksContainerHeight(count: number, defaultHeight) {
  switch (count) {
    case 0:
      return "150px";
    case 1:
      return "max-content";
    case 2:
      return "max-content";
    default:
      return defaultHeight;
  }
}

interface BaseProps {
  task: TaskType;
  assignee_id: string;
}

interface TaskActionProps extends BaseProps {
  action: string;
}

interface TaskProps extends BaseProps {
  tasksStatuses: TaskStatusEnum[];
  user?: UserTypeInner;
  showResolveButton: boolean;
  showActionButtons: boolean;
  currentRole: RolesEnum;
}

function CallButton({ task, assignee_id }: BaseProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const taskRead = hasReadTask(task);
  const [addActionsToTaskMutation, { error: addActionsToTaskError }] =
    useAddActionsToTaskMutation();

  useEffect(() => {
    if (addActionsToTaskError) {
      const id = `call_add_action_error_${task?.task?.task_id}_${assignee_id}`;
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={addActionsToTaskError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [addActionsToTaskError]);

  return (
    <StyledOutlineLoadingButton
      variant="outlined"
      onClick={() => {
        if (!taskRead) {
          addActionsToTaskMutation({
            task_id: task?.task?.task_id,
            body: [
              {
                type: "READ",
                occurred_at: DateTime.now().toUTC(),
                performed_by: assignee_id,
                modified_by: assignee_id
              }
            ],
            assignee_id,
            refreshDataRouteChanged: true
          });
        }

        callPatientModalSelector(dispatch, task?.patient, () => navigate(link));

        const link = replace(MEMBERS_PATH, ":memberId", task?.task?.patient_id);
      }}
    >
      Call
    </StyledOutlineLoadingButton>
  );
}

function UpdateCallStatusButton({ task, assignee_id }: BaseProps) {
  const dispatch = useAppDispatch();
  const taskRead = hasReadTask(task);
  const [addActionsToTaskMutation, { error: addActionsToTaskError }] =
    useAddActionsToTaskMutation();
  const taskId = task?.task?.task_id;

  useEffect(() => {
    if (addActionsToTaskError) {
      const id = `call_add_action_error_${task?.task?.task_id}_${assignee_id}`;
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={addActionsToTaskError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [addActionsToTaskError]);

  const taskType = task?.task?.type;
  return (
    <StyledOutlineLoadingButton
      variant="outlined"
      onClick={() => {
        if (!taskRead) {
          addActionsToTaskMutation({
            task_id: task?.task?.task_id,
            body: [
              {
                type: "READ",
                occurred_at: DateTime.now().toUTC(),
                performed_by: assignee_id,
                modified_by: assignee_id
              }
            ],
            assignee_id
          });
        }
        Alert_show({
          dispatch,
          id: taskId,
          title:
            taskType === TaskTypeEnum.BILLING_MISSING_INSURANCE ||
            taskType === TaskTypeEnum.BILLING_MISSING_DIAGNOSIS
              ? "Update Resolution Outcome"
              : "Update Call Outcome",
          content: (
            <UpdateCallOutcomeForm
              key={taskId}
              assigneeId={assignee_id}
              task={task}
            />
          ),
          // buttons are in the UpdateCallOutcomeForm
          buttons: []
        });
      }}
    >
      Update
    </StyledOutlineLoadingButton>
  );
}

function TaskAction({ action, task, assignee_id }: TaskActionProps) {
  if (action === "CALL") {
    return <CallButton task={task} assignee_id={assignee_id} />;
  }

  return <UpdateCallStatusButton task={task} assignee_id={assignee_id} />;
}

function getTaskActionButtons(
  task: TaskType,
  assignee_id: string,
  user: UserTypeInner,
  showResolveButton = true,
  showActionButtons = true,
  currentRole: RolesEnum
) {
  return (
    <Flexbox alignItems="center" gap="8px">
      {canSeeCareFlow(currentRole) && showActionButtons ? (
        <StartCareFlow
          visitsRequest={{
            staff_id: user?.user_id,
            patient_id: task?.patient?.patient_id,
            motivation_reason: VisitMotivationTypesEnum.URGENT_ALERT,
            task_id: task?.task?.task_id
          }}
        />
      ) : (
        <>
          {showActionButtons && (
            <>
              {task?.possible_actions?.map((action, i) => (
                <TaskAction
                  key={`${i}${task?.task?.task_id}`}
                  action={action}
                  task={task}
                  assignee_id={assignee_id}
                />
              ))}
            </>
          )}
          {showResolveButton && (
            <TaskAction
              key={`resolve${task?.task?.task_id}`}
              action="RESOLVE"
              task={task}
              assignee_id={assignee_id}
            />
          )}
        </>
      )}
    </Flexbox>
  );
}

function ContactAttemptComponent({
  contactAttempts
}: {
  contactAttempts: Readonly<TaskActionType>[];
}) {
  return (
    <>
      {contactAttempts.map((contactAttempt: TaskActionType) => {
        return (
          <div key={contactAttempt.occurred_at}>
            {`${formatName(contactAttempt.type)}, 
              ${DateTime.fromISO(contactAttempt.occurred_at).toFormat(
                "LLL dd, h:mm a"
              )} by ${getNameOrUsername(contactAttempt?.performed_by_info, false) || "N/A"}, Status: ${formatName(contactAttempt.outcome)}
            `}
          </div>
        );
      })}
    </>
  );
}

/**
 * 
 This component renders the task
  * @param {string} tasksStatuses A comma separated list of task statuses to fetch

 */

function Task({
  tasksStatuses,
  task,
  assignee_id,
  showResolveButton,
  showActionButtons,
  user,
  currentRole
}: TaskProps) {
  const backgroundColor = getTaskColor(task?.task?.priority);
  const taskRead = hasReadTask(task);

  const contactAttempts = task?.task?.activity?.number_of_contact_attempts;
  const mostRecentContactAttempt =
    task?.task?.activity?.most_recent_contact_attempt;

  const contactAttemptsData = task?.task?.activity?.actions?.filter((action) =>
    // PHONE_CALL for nurse tasks
    [
      TaskActionTypeEnum.PHONE_CALL,
      // RESOLVED for billing tasks
      TaskActionTypeEnum.RESOLVED
    ].includes(action.type)
  );

  return (
    <TaskContainer backgroundColor={backgroundColor}>
      <Flexbox
        alignItems="center"
        gap="12px"
        justifyContent="space-between"
        alignSelf="stretch"
      >
        <Flexbox gap="12px" alignItems="center">
          <div>
            <StyledDangerIcon />
          </div>
          <div>{getTaskText(task, taskRead, assignee_id)}</div>
        </Flexbox>

        {!tasksStatuses.includes(TaskStatusEnum.COMPLETED) && (
          <div>
            {getTaskActionButtons(
              task,
              assignee_id,
              user,
              showResolveButton,
              showActionButtons,
              currentRole
            )}
          </div>
        )}
      </Flexbox>
      <TaskContactAttemptsContainer>
        {contactAttempts > 0 && (
          <Flexbox alignItems="center">
            <AccordionComponent
              closedHeader={` Show Update History (Last attempt:
                  ${DateTime.fromISO(
                    mostRecentContactAttempt?.occurred_at
                  ).toFormat("LLL dd, h:mm a")}
                  ) by ${getNameOrUsername(mostRecentContactAttempt?.performed_by_info, false) || "N/A"}`}
              openHeader={"Hide Update History"}
              content={
                <ContactAttemptComponent
                  contactAttempts={contactAttemptsData}
                />
              }
            />
            &nbsp;
          </Flexbox>
        )}
      </TaskContactAttemptsContainer>
    </TaskContainer>
  );
}

interface TaskLink {
  text: string;
  route: string;
}

const Tasks = ({
  tasksStatuses,
  componentHeader,
  link,
  noTasksMessage,
  tableHeight = "275px",
  showWhenNoTasks = true,
  showTaskCount = true,
  datePickerEnabled = false,
  showResolveButton,
  showActionButtons,
  tooltipContent,
  componentHeaderMarginBottom = "0px",
  tasksMarginBottom = "0px",
  outerContainerMargin = "2.5%",
  customTasksContainerHeight = null,
  nurseId: propsNurseId,
  memberId: propsMemberId
}: {
  tasksStatuses: TaskStatusEnum[];
  componentHeader: string;
  noTasksMessage: string;
  tableHeight?: string;
  showWhenNoTasks?: boolean;
  showTaskCount?: boolean;
  datePickerEnabled?: boolean;
  link?: TaskLink;
  showResolveButton?: boolean;
  showActionButtons?: boolean;
  tooltipContent?: string;
  componentHeaderMarginBottom?: string;
  tasksMarginBottom?: string;
  outerContainerMargin?: string;
  customTasksContainerHeight?: string;
  nurseId?: string;
  memberId?: string;
}) => {
  const { nurseId: paramsNurseId, memberId: paramsMemberId } =
    useSanitizedParams();

  const [startsAfter, setStartsAfter] = useState<DateTime>(
    DateTime.now().minus({ days: 7 }).startOf("day")
  );
  const [startsBefore, setStartsBefore] = useState<DateTime>(
    DateTime.now().endOf("day")
  );

  const nurseId = paramsNurseId || propsNurseId;
  const memberId = paramsMemberId || propsMemberId;
  const isValidNurseId = checkIdValid(nurseId);
  const isValidMemberId = checkIdValid(memberId);

  const invalidNurseId = !isFalsy(nurseId) && !checkIdValid(nurseId);
  const invalidMemberId = !isFalsy(memberId) && !checkIdValid(memberId);

  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  let assignee_id = nurseId ?? user?.user_id;

  const tasksParams: TaskParams = {};

  if (
    currentRole === RolesEnum.RCM_ADMIN ||
    currentRole === RolesEnum.PROVIDER_MANAGER
  ) {
    tasksParams.assignee_id = user.user_id;
  } else if (isValidNurseId) {
    tasksParams.assignee_id = sanitizeId(nurseId);
  }

  if (isValidMemberId) {
    tasksParams.patient_ids = sanitizeId(memberId);
  }

  if (Object.keys(tasksParams).length === 0) {
    // default is assignee id if no nurse or patient ID is passed
    tasksParams.assignee_id = user?.user_id;
    assignee_id = user?.user_id;
  }

  tasksParams.status = tasksStatuses;
  tasksParams.shouldShowBillingTasks = canSeeBillingTasks(currentRole);
  tasksParams.modified_at_after = datePickerEnabled ? startsAfter : undefined;
  tasksParams.modified_at_before = datePickerEnabled ? startsBefore : undefined;

  const {
    data: tasksData,
    error: tasksError,
    isFetching: tasksIsFetching,
    isSuccess: tasksIsSuccess
  } = useGetTasksQuery(tasksParams, {
    // if all 3 values are false, skip making request
    skip: invalidNurseId || invalidMemberId
  });

  const {
    data: tasksCountData,
    error: tasksCountError,
    isError: tasksCountIsError,
    isSuccess: tasksCountIsSuccess
  } = useGetTasksCountQuery(
    {
      assignee_id: assignee_id,
      // tbd need to filter this on the backend
      shouldShowBillingTasks: canSeeBillingTasks(currentRole),
      modified_at_after: datePickerEnabled ? startsAfter : undefined,
      modified_at_before: datePickerEnabled ? startsBefore : undefined
    },
    { skip: isFalsy(assignee_id) || invalidNurseId || invalidMemberId }
  );

  const ChipStyles = {
    color: "#475467",
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: "12px",
    height: "24px"
  };

  const tasks = useMemo(() => {
    return (
      <>
        {tasksData?.map((task) => {
          return (
            <Task
              showResolveButton={showResolveButton}
              showActionButtons={showActionButtons}
              tasksStatuses={tasksStatuses}
              key={task?.task?.task_id}
              task={task}
              assignee_id={assignee_id}
              currentRole={currentRole}
            />
          );
        })}
      </>
    );
  }, [tasksData, currentRole]);

  const taskStatusesCount = tasksStatuses.reduce((acc, status) => {
    acc += tasksCountData?.[status];
    return acc;
  }, 0);

  if (!canSeeTasks(currentRole)) {
    return null;
  }

  if (tasksData?.length === 0 && tasksIsSuccess && !showWhenNoTasks) {
    return null;
  }

  return (
    <Box margin={outerContainerMargin}>
      <Box mb={tasksMarginBottom}>
        <Flexbox gap="8px" alignItems="center">
          <Flexbox
            justifyContent="space-between"
            alignItems="baseline"
            width="100%"
          >
            {/* 
              Do not use any loading or success false/true boolean because it breaks <DropdownDateRangePicker picker. 
              This picker should always be rendered, otherwise the state gets reset.
              https://copilotiq.atlassian.net/browse/QA-355
            */}
            {tasksData && (
              <Flexbox
                gap="8px"
                alignItems="center"
                mb={componentHeaderMarginBottom}
                display={"flex"}
                flexDirection={"row"}
                flex={1}
              >
                <Typography variant="h4" color="text.secondary">
                  {componentHeader}
                </Typography>{" "}
                {tooltipContent && (
                  <CustomTooltip
                    backgroundColor={gray[200]}
                    title={
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        maxWidth="225px"
                      >
                        {tooltipContent}
                      </Typography>
                    }
                  >
                    <InfoOutlined color="primary" />
                  </CustomTooltip>
                )}
                {tasksCountIsSuccess &&
                  showTaskCount &&
                  taskStatusesCount >= 0 && (
                    <Chip
                      sx={ChipStyles}
                      label={taskStatusesCount}
                      variant="outlined"
                    />
                  )}
                {tasksCountIsError && (
                  <CustomTooltip
                    backgroundColor="#ffffff"
                    title={<ErrorComponent error={tasksCountError} />}
                  >
                    <StyledDangerIcon />
                  </CustomTooltip>
                )}
                <Fill />
                {datePickerEnabled && (
                  <DropdownDateRangePicker
                    onEndDateChange={setStartsBefore}
                    onStartDateChange={setStartsAfter}
                    endDate={startsBefore}
                    startDate={startsAfter}
                  />
                )}
              </Flexbox>
            )}
            {link && (
              <StyledInternalLink to={link.route}>
                <Typography variant="h5">{link.text}</Typography>
              </StyledInternalLink>
            )}
          </Flexbox>
        </Flexbox>

        {invalidNurseId || invalidMemberId ? (
          <Typography variant="body1" color="error" mb="2.5%">
            {invalidNurseId && `Error: Invalid Nurse ID ${nurseId}`}
            {invalidMemberId && `Error: Invalid Member ID ${memberId}`}
          </Typography>
        ) : (
          <>
            {tasksIsFetching && <Skeleton count={5} />}
            {!tasksIsFetching && tasksIsSuccess && (
              <TasksContainer
                {...(hasNurseRole([currentRole])
                  ? { borderColor: "darkred" }
                  : "")}
                height={
                  customTasksContainerHeight ??
                  getTasksContainerHeight(tasksData?.length, tableHeight)
                }
              >
                {tasksData?.length === 0 && (
                  <Flexbox
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Flexbox
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      padding="8px"
                      gap="8px"
                    >
                      <CircleOutlinedContainer>
                        <StyledApproveFileIcon />
                      </CircleOutlinedContainer>
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "center" }}
                        color="text.secondary"
                      >
                        {noTasksMessage}
                      </Typography>
                    </Flexbox>
                  </Flexbox>
                )}
                {tasks}
                {tasksError && <ErrorComponent error={tasksError} />}
              </TasksContainer>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Tasks;
