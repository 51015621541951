import RolesEnum from "common/enums/RolesEnum";
import { DocumentIcon, PersonIcon } from "../../assets/images/icons";
import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  NPN_LIST,
  NPN_DETAILS,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  STAFF_MEMBERS,
  REPORT_LIST,
  REPORT_DETAILS,
  CANCELED_MEMBERS,
  RETENTION,
  REPORT_DETAILS_BY_MEMBER,
  ALL_TEAMS,
  TEAM_DETAILS,
  CREATE_TEAM,
  REGISTER_MEMBER,
  REQUEST_CONSENTS,
  MEMBER_CHART,
  PROVIDER_LIST,
  MEMBER_CHART_CALENDARING,
  STAFF_DETAILS,
  NURSE_SCHEDULE,
  MY_TODOS,
  COMPLETED_TODOS,
  VISITS,
  NURSES_SCHEDULES,
  ARC_OF_DAY_DASHBOARD,
  ARC_OF_DAY_DETAILS,
  PROVIDER_SCHEDULES,
  COMMON_ROUTES,
  START_INTAKE
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_INACTIVE_CANCELED_MEMBERS,
  SIDEBAR_RETENTION,
  SIDEBAR_CREATE_TEAM,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_NPNS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_SECTIONS,
  PROVIDER_SCHEDULES_SIDEBAR
} from "../RouteSidebar";
import MemberDetailsRedirect from "../../pages/MemberDetails/MemberDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import CreateUserForm from "../../pages/CreateUserForm/CreateUserForm";

const providerSidebar = () => {
  const sidebarItems = [
    {
      itemName: "All Providers",
      itemLink: "/providers",
      ItemIcon: PersonIcon
    }
  ];

  sidebarItems.push(PROVIDER_SCHEDULES_SIDEBAR);

  return sidebarItems;
};

const ADMIN = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: "/",
      components: STAFF_MEMBERS
    },
    {
      path: "/create-user",
      components: <CreateUserForm />
    },
    CREATE_TEAM,
    ALL_TEAMS({}),
    TEAM_DETAILS,
    STAFF_DETAILS,
    {
      path: "/nurses",
      components: NURSE_LIST({})
    },
    {
      path: "/nurses/nurseId/:userId",
      components: <MemberDetailsRedirect />
    },
    NURSE_SCHEDULE,
    ...NURSES_SCHEDULES,

    {
      path: "/members",
      components: ALL_MEMBERS({})
    },
    {
      path: "/awaiting-provider",
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: "/inactive-members", components: INACTIVE_MEMBERS(true) },

    ...MEMBER_CHART(RolesEnum.ADMIN),
    ...MEMBER_CHART_CALENDARING,
    {
      path: "/members/nurseId/:nurseId",
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: "/orders",
      components: [<OrderDevices key="OrderDevices" />, ALL_RECENT_ORDERS()]
    },
    {
      path: "/devices/:memberId",
      components: <OrderDevices />
    },
    START_INTAKE,
    ORDER_DETAILS(),
    { path: "/register-member", components: REGISTER_MEMBER },
    { path: "/request-consents", components: REQUEST_CONSENTS },

    {
      path: "/providers",
      components: PROVIDER_LIST({
        filterByAuthenticatedUser: false
      })
    },
    {
      path: "/provider-schedules",
      components: PROVIDER_SCHEDULES({})
    },
    NPN_LIST({}),
    NPN_DETAILS,
    RETENTION({}),
    REPORT_LIST,
    REPORT_DETAILS,
    CANCELED_MEMBERS,
    REPORT_DETAILS_BY_MEMBER,
    CREATE_TEAM,
    MY_TODOS,
    COMPLETED_TODOS,
    VISITS,
    ARC_OF_DAY_DASHBOARD,
    ARC_OF_DAY_DETAILS,
    {
      path: "/staff-members",
      components: STAFF_MEMBERS
    }
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.users,
      items: [
        {
          itemName: "Create User",
          itemLink: "/create-user",
          ItemIcon: DocumentIcon
        },
        SIDEBAR_CREATE_TEAM,
        {
          itemName: "Register Member",
          itemLink: "/register-member",
          ItemIcon: PersonIcon
        },
        {
          itemName: "Request Consents",
          itemLink: "/request-consents",
          ItemIcon: PersonIcon
        },
        SIDEBAR_CLINICAL_TEAMS,
        {
          itemName: "Staff Members",
          itemLink: "/staff-members",
          ItemIcon: DocumentIcon
        }
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "All Nurses",
          itemLink: "/nurses",
          ItemIcon: PersonIcon
        },
        {
          itemName: "Nurses Schedules",
          itemLink: "/nurses-schedules/schedules",
          ItemIcon: PersonIcon
        },
        SIDEBAR_NPNS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.providers,
      items: providerSidebar()
    },
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    {
      sectionName: SIDEBAR_SECTIONS.retention,
      items: [SIDEBAR_RETENTION, SIDEBAR_INACTIVE_CANCELED_MEMBERS]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default ADMIN;
