import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useUpdateEncounterMutation } from "common/services/EncountersService";
import { CheckBox, Edit } from "@mui/icons-material";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import StyledIconButton from "../../Button/StyledIconButton";
import { useUpdateVisitMutation } from "common/services/VisitsService";
import { Alert_show } from "common/helpers/AlertHelper";
import ErrorComponent from "../../ErrorComponent";
import { useAppDispatch } from "common/redux";
import NumberInput from "../../Input/NumberInput";

const InputContainer = styled.div`
  display: flex;
`;

// Create an editable cell renderer
export function EditEncounterCell({
  value: initialValue,
  row,
  id,
  editable
}: any) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const encounterId = row?.original?.encounter?.encounter_id;
  const visitId = row?.original?.visit?.visit_id;

  const [isEditable, setIsEditable] = useState<boolean>(false);
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState<number>(parseInt(initialValue));

  // we don't need to access data here since invalidating the tag will refetch the data
  // we just need to handle errors
  const [
    updateEncounterMutation,
    {
      isError: updateEncounterIsError,
      isLoading: updateEncounterIsLoading,
      error: updateEncounterError,
      reset: resetUpdateEncounter
    }
  ] = useUpdateEncounterMutation();

  const [
    updateVisitMutation,
    {
      isError: updateVisitIsError,
      isLoading: updateVisitIsLoading,
      error: updateVisitError,
      reset: resetUpdateVisit
    }
  ] = useUpdateVisitMutation();

  const onChange = (n: number) => {
    setValue(n);
  };

  useEffect(() => {
    if (updateEncounterIsError && editable) {
      setValue(initialValue);
      setIsEditable(false);
    }
    if (updateEncounterIsError) {
      Alert_show({
        dispatch,
        id: "updateError",
        title: "Something went wrong updating the encounter.",
        content: <ErrorComponent error={updateEncounterError} />,
        size: "small",
        type: "warning"
      });
    }
  }, [updateEncounterIsError, editable]);

  useEffect(() => {
    if (updateVisitIsError && editable) {
      setValue(initialValue);
      setIsEditable(false);
    }

    if (updateVisitIsError) {
      Alert_show({
        dispatch,
        id: "updateError",
        title: "Something went wrong updating the encounter.",
        content: <ErrorComponent error={updateVisitError} />,
        size: "small",
        type: "warning"
      });
    }
  }, [updateVisitIsError, editable]);

  const handleUpdateEncounter = (event) => {
    event.stopPropagation();
    if (value > 0 && value <= 60 && value !== initialValue) {
      updateEncounterMutation({
        encounterId,
        data: { duration: value }
      });
    } else if (value === initialValue) {
      // do nothing
      setIsEditable(false);
    } else {
      alert("Please enter a number between 0 and 60");
    }
  };

  const handleUpdateVisit = (e) => {
    if (value > 0 && value <= 60 && value !== initialValue) {
      if (row?.original?.visit?.talk_time === 0) {
        updateVisitMutation({
          body: {
            total_time: value
          },
          visit_id: visitId
        });
      } else {
        updateVisitMutation({
          body: {
            total_time: value,
            talk_time: value
          },
          visit_id: visitId
        });
      }
    } else if (value === initialValue) {
      // do nothing
      setIsEditable(false);
    } else {
      alert("Please enter a number between 0 and 60");
    }
  };

  const handleUpdateEncounterClick = (e) => {
    if (encounterId) {
      handleUpdateEncounter(e);
    }
    if (visitId) {
      handleUpdateVisit(e);
    }
  };

  const handleResetEncounter = (e) => {
    e.stopPropagation();
    // https://redux-toolkit.js.org/rtk-query/usage/mutations
    resetUpdateEncounter();
    resetUpdateVisit();
    setIsEditable(true);
  };

  const isLoading = updateEncounterIsLoading || updateVisitIsLoading;

  return (
    <>
      {isEditable ? (
        <InputContainer>
          <NumberInput
            id={"timeSpent"}
            name={"timeSpent"}
            data-testid="timeSpentInput"
            value={value}
            onValueChange={onChange}
            sx={{ width: 50 }}
            min={1}
            max={60}
          />

          {isLoading ? (
            <CircularProgress />
          ) : (
            <StyledIconButton
              Icon={CheckBox}
              sx={{ marginLeft: 1 }}
              iconColor={theme.palette.primary.main}
              onClick={handleUpdateEncounterClick}
              color={"transparent"}
            />
          )}
        </InputContainer>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant={"body1"} color="primary">
            {value}
          </Typography>
          {editable && (
            <StyledIconButton
              Icon={Edit}
              sx={{ marginLeft: 1 }}
              iconColor={theme.palette.primary.main}
              onClick={handleResetEncounter}
              color={"transparent"}
              size={"small"}
            />
          )}
        </div>
      )}
    </>
  );
}
