import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { DateTime } from "luxon";
import MemberType from "common/types/MemberType";
import { useEffect } from "react";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import {
  useGetTasksQuery,
  useUpdateTaskStatusMutation
} from "common/services/TaskingService";
import { isFalsy } from "common/helpers/helpers";
import Tasks from "../../Tasks/Tasks";
import TaskActionTypeEnum from "common/enums/TaskActionTypeEnum";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import CallOutcomeEnum from "common/enums/CallOutcomeEnum";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { isDisabled } from "../VisitHelper";

interface IProps {
  member: MemberType;
  field?: CareFlowFieldType;
  visit?: GetVisitResponseType;
}

const VisitFieldUrgentAlertBanners = ({ member }: IProps) => {
  return (
    <Tasks
      tasksStatuses={[TaskStatusEnum.TODO]}
      componentHeader=""
      showWhenNoTasks={false}
      noTasksMessage=""
      showResolveButton={false}
      showActionButtons={false}
      tooltipContent=""
      outerContainerMargin="0px"
      memberId={member?.patient?.patient_id}
      showTaskCount={false}
    />
  );
};

const VisitFieldUrgentAlertActions = ({ visit, member }: IProps) => {
  const { answers } = useSelector((state: RootState) => state.visits);
  const { data } = useGetTasksQuery(
    { patient_ids: member?.patient?.patient_id, status: [TaskStatusEnum.TODO] },
    {
      // if all 3 values are false, skip making request
      skip: isFalsy(member?.patient?.patient_id)
    }
  );

  const [updateTaskStatusMutation] = useUpdateTaskStatusMutation();

  useEffect(() => {
    if (isDisabled(visit))
      return

    const now = DateTime.now().setZone("utc").toISO();
    const outcome =
      answers[CareFlowFieldIdsEnum.CALL_CONNECTION] === "Connected"
        ? CallOutcomeEnum.CONTACTED
        : CallOutcomeEnum.DISMISS;
    data?.forEach((task) => {
      updateTaskStatusMutation({
        task_id: task?.task?.task_id,
        status: TaskStatusEnum.COMPLETED,
        assignee_id: visit?.staff_id,
        body: [
          {
            type: TaskActionTypeEnum.READ,
            occurred_at: now,
            performed_by: visit?.staff_id,
            modified_by: visit?.staff_id
          },
          {
            type: TaskActionTypeEnum.PHONE_CALL,
            outcome,
            occurred_at: now,
            performed_by: visit?.staff_id,
            modified_by: visit?.staff_id
          }
        ]
      });
    });
  }, [data]);

  // No actual rendered component, this will auto-update actions depending on whether the call was Connected / Not Connected
  return <></>;
};

export { VisitFieldUrgentAlertBanners, VisitFieldUrgentAlertActions };
