import { useEffect, useState } from "react";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { Column } from "../../../styling/StyleComponents";
import { getField, isDisabled } from "../VisitHelper";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "common/redux";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { setAnswer } from "common/redux/VisitsSlice";
import { gray } from "common/styling/colors";
import GoogleDocLinks from "common/config/GoogleDocLinks";

interface IProps {
  visit: GetVisitResponseType;
}

interface CriticalIssuesFieldType {
  fieldId: string;
  label: string;
  checked: boolean;
  notes: string;
}

interface CriticalIssueSectionType {
  sectionName: string;
  fields: CriticalIssuesFieldType[];
}

const VisitFieldCriticalIssues = ({ visit }: IProps) => {
  const { answers } = useSelector((state: RootState) => state.visits);
  const disabled = isDisabled(visit);
  const [criticalIssues, setCriticalIssues] =
    useState<CriticalIssueSectionType[]>(null);
  const [noCriticalIssues, setNoCriticalIssues] = useState<boolean>(true);

  useEffect(() => {
    if (visit) calculateCriticalIssues();
  }, [visit]);

  const criticalSymptomsFieldIds = [
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_CHEST_PAIN,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_SHORTNESS_OF_BREATH,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_SEVERE_HEADACHE,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_CONFUSION,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_LOSS_OF_CONSCIOUSNESS,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_FACIAL_DROOP,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_SEIZURE,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_GI_ISSUES,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_SUICIDAL_THOUGHTS,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_ABUSE,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_SYMPTOMS_OTHER
  ];

  const deviceIssuesFieldIds = [
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_DEVICE_ISSUE_QUESTIONS,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_DEVICE_USAGE_QUESTIONS
  ];

  const refillsFieldIds = [
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_NEEDS_REFILLS,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_NEEDS_MEDICATIONS
  ];

  const otherFieldIds = [
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_HAS_CLINICAL_QUESTION,
    CareFlowFieldIdsEnum.CALL_MEMBER_NEEDS_REQUESTS_CLINICAL_COACHING
  ];

  const getCorrespondingMemberNeed = async (
    fieldId
  ): Promise<CriticalIssuesFieldType> => {
    // Get the member need field; set value as a label
    // If checked, render the corresponding ISSUE_NOTES field
    const field = await getField(visit?.care_flow, fieldId);
    const isChecked = answers[fieldId] === "true" || answers[fieldId] === true;
    const notes = answers[`${fieldId}_NOTES`] as string;

    return {
      fieldId: fieldId,
      label: field?.label,
      checked: isChecked,
      notes: notes
    };
  };

  const getSectionFields = async (
    sectionName,
    fieldIds
  ): Promise<CriticalIssueSectionType> => {
    const section = {
      sectionName: sectionName,
      fields: []
    };
    for (const fieldId of fieldIds) {
      const field = await getCorrespondingMemberNeed(fieldId);
      if (field.checked) {
        setNoCriticalIssues(false);
        section.fields.push(field);
      }
    }

    return section;
  };

  // Based on the fields selected in the visit member needs, show/hide fields in field.subfields
  const calculateCriticalIssues = async () => {
    // Loop through fields in each section; if present, add to the section
    const sections = [
      await getSectionFields("Critical Symptoms", criticalSymptomsFieldIds),
      await getSectionFields("Device Tech/Usage Issue", deviceIssuesFieldIds),
      await getSectionFields("Refills", refillsFieldIds),
      await getSectionFields("Other", otherFieldIds)
    ];

    setCriticalIssues(sections);
  };

  // Render subfields if corresponding checkboxes are checked
  return (
    <Column>
      <Typography variant="body1" color={gray[900]}>
        If there are any critical symptoms, please follow the{" "}
        <Typography
          variant="caption"
          color={"primary"}
          style={{ cursor: "pointer", fontSize: "100%" }}
          onClick={() =>
            window.open(GoogleDocLinks.CLINICAL_ESCALATION_CRITERIA, "_blank")
          }
        >
          <b>Clinical Escalation Criteria</b>
        </Typography>{" "}
        procedure.
      </Typography>
      {noCriticalIssues && (
        <Typography variant="h6" color={gray[900]} margin={"20px 0px"}>
          No Critical Issues were identified during Member Needs
        </Typography>
      )}
      {criticalIssues?.map((section) => {
        if (!section.fields || section.fields.length === 0) return;
        return (
          <>
            <Typography
              variant="h6"
              color={gray[900]}
              textTransform={"uppercase"}
              margin={"5px 5px 10px 0px"}
            >
              {section.sectionName}
            </Typography>
            {section?.fields?.map((field) => {
              const criticalIssueNoteFieldId = `${field.fieldId}_ISSUE_NOTES`;
              return (
                <>
                  <Typography variant={"h6"}>{field.label}</Typography>
                  <Typography variant={"h6"} color="text.secondary">
                    {field.notes ?? ""}
                  </Typography>
                  <TextField
                    data-testid={criticalIssueNoteFieldId}
                    label={"Add notes"}
                    fullWidth
                    multiline
                    rows={3}
                    value={answers[criticalIssueNoteFieldId] ?? ""}
                    disabled={disabled}
                    sx={{
                      input: {
                        color: gray[900]
                      }
                    }}
                    onChange={(event) => {
                      dispatch(
                        setAnswer({
                          id: criticalIssueNoteFieldId,
                          value: event.target.value
                        })
                      );
                    }}
                  />
                </>
              );
            })}
          </>
        );
      })}
    </Column>
  );
};

export default VisitFieldCriticalIssues;
