import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import cloneDeep from "lodash.clonedeep";
import c3 from "c3";
import "c3/c3.css";
import "react-loading-skeleton/dist/skeleton.css";
import useGetMemberDataSummary from "common/hooks/useGetMemberDataSummary";
import MemberType from "common/types/MemberType";
import {
  AvgTextContainer,
  ChartIconContainer,
  ChartIconSVG,
  ChartSubHeaderText,
  ThreeLinesContainer,
  ThreeLinesSVG,
  transformBloodPressureDataToColumns
} from "../../../helpers/components/Charts/ChartHelpers";
import ErrorComponent from "../../../components/ErrorComponent";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import ReadingChartPropsType from "common/types/common/ReadingChartPropsType";
import { gray } from "common/styling/colors";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { Button, Typography } from "@mui/material";
import { canDownloadChart } from "common/enums/RolesEnum";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { FileDownloadOutlined } from "@mui/icons-material";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import BloodPressureTable from "./BloodPressureTable";
import { DateTime } from "luxon";
import ReportEnum from "../../../enums/ReportEnum";
import StorageHelper from "common/helpers/StorageHelper";
import StorageEnum from "common/enums/StorageEnum";
import { Column } from "../../../styling/StyleComponents";
import ChartControls from "./ChartControls";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";

const PulseChartContainer = styled.div`
  margin: 12px 0;
  border-radius: 4px;
  border: 1px solid ${gray[300]};
  background: #ffffff;
  padding: 18px 8px;
  height: 100%;
  transition: all 0.66s ease-out;

  #bloodPressurePulseChart {
    height: 450px;
    margin-left: 18px;
  }

  #bloodPressurePulseChart svg {
    width: calc(100%);
  }

  #bloodPressurePulseChart .c3-circle {
    r: 3;
  }

  #bloodPressurePulseChart .c3-circle._expanded_ {
    visibility: visible;
    r: 4;
  }

  #bloodPressurePulseChart .c3-legend-background {
    stroke: none;
  }

  // dashed lines
  .c3-shapes-SYSTOLIC.c3-lines.c3-lines-SYSTOLIC {
    stroke-dasharray: 5, 5;
  }
  .c3-shapes-DIASTOLIC.c3-lines.c3-lines-DIASTOLIC {
    // stroke-dasharray: 5, 5;
  }

  // hide lines

  .c3-shapes-DIASTOLIC.c3-lines.c3-lines-DIASTOLIC > path {
    stroke: none !important;
  }

  .c3-shapes-SYSTOLIC.c3-lines.c3-lines-SYSTOLIC > path {
    stroke: none !important;
  }

  .c3-shapes-PULSE.c3-lines.c3-lines-PULSE > path {
    stroke: none !important;
  }

  .c3-line-PULSE-SMA-7-DAY {
    stroke-width: 4px;
  }

  // hide dots

  .c3-circles-PULSE-SMA-7-DAY {
    display: none !important;
  }

  .c3-axis-x-label {
    font: 15px "Inter";
  }
  .c3-axis-y-label {
    font: 15px "Inter";
  }

  #bloodPressurePulseChart .c3-legend-item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: rgba(24, 24, 25, 0.42);
  }

  .tick line {
    visibility: hidden;
  }
`;

const PulseChart = ({
  patient,
  deviceIds,
  handleDataChange,
  showAverages,
  dateFilter,
  defaultDays = 30
}: ReadingChartPropsType) => {
  const navigate = useNavigate();
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [showTable, setShowTable] = useState<boolean>(true);

  useEffect(() => {
    StorageHelper.getItem(StorageEnum.CHART_SHOW_TABLE + "_pulse").then(
      (item) => {
        if (item) setShowTable(item === "true");
      }
    );
  }, []);

  const toggleTable = () => {
    setShowTable(!showTable);

    StorageHelper.setItem(
      StorageEnum.CHART_SHOW_TABLE + "_pulse",
      "" + !showTable
    );
  };

  const { bloodPressure } = useGetMemberDataSummary({
    patient,
    days: dateFilter,
    fetchAPIs: [DeviceTypeEnum.BLOOD_PRESSURE]
  });

  const serialNumbers = useMemo(() => {
    if (!bloodPressure?.data) return;

    const array = bloodPressure?.data.map((reading) => reading.device_id);
    return array?.filter((item, index) => array.indexOf(item) === index);
  }, [bloodPressure?.data]);

  const patientTimezone = useMemo(() => patient?.patient?.timezone, [patient]);

  const data = useMemo(() => {
    if (bloodPressure?.data === undefined) return;
    const dataCopy = cloneDeep(bloodPressure?.data);
    return transformBloodPressureDataToColumns(
      deviceIds?.length > 0
        ? dataCopy.filter((item) => deviceIds.includes(item.device_id))
        : dataCopy,
      patientTimezone
    );
  }, [bloodPressure?.data, patientTimezone, deviceIds]);

  useEffect(() => {
    if (data && !showTable) {
      const {
        columns,
        pulseArr,
        pulseSimpleMovingAvgArr,
        xAxisParams,
        tickArr
      } = data;

      const [dateArr] = columns;

      const pulseColumns = [
        ["PULSE", ...pulseArr],
        dateArr,
        pulseSimpleMovingAvgArr
      ];

      // remove averages
      if (!showAverages) {
        pulseColumns.pop();
      }

      // possible future enhancement could be a dropdown to toggle between different modes, like line, spline, area, bar, etc.

      let pulseTypes: any = {
        PULSE: "spline"
      };

      if (showAverages) {
        pulseTypes = {
          PULSE: "spline",
          "PULSE AVG": "line"
        };
      }

      if (dateArr && pulseArr) {
        if (bloodPressure?.data?.length === 0) {
          // if no data found, display "No Data Found" chart
          c3.generate({
            bindto: "#bloodPressurePulseChart",
            data: {
              x: "date",
              xFormat: "%m/%d/%Y %H:%M",
              columns: pulseColumns
            },
            axis: {
              x: {
                type: "category",
                label: {
                  text: "No readings taken in selected time range",
                  position: "inner-right"
                },
                tick: {
                  outer: false
                },
                ...xAxisParams
              },
              y: {
                tick: {
                  outer: false
                },
                label: "bpm"
              }
            },
            padding: {
              bottom: 20 //adjust chart padding bottom
            },
            legend: {
              hide: ["PULSE"]
            }
          });
        } else {
          c3.generate({
            bindto: "#bloodPressurePulseChart",
            data: {
              x: "date",
              xFormat: "%m/%d/%Y, %H:%M:%S %p",
              columns: pulseColumns,
              types: pulseTypes
            },
            axis: {
              x: {
                type: "timeseries",
                tick: {
                  outer: false,
                  format: "%b %d",
                  values: tickArr,
                  fit: true,
                  rotate: 0
                },
                ...xAxisParams
              },
              y: {
                tick: {
                  outer: false
                },
                label: "bpm"
              }
            },
            padding: {
              bottom: 20 //adjust chart padding bottom
            },
            legend: {
              hide: ["PULSE"]
            }
          });
        }
      }
    }
  }, [data, showAverages, showTable, bloodPressure?.data?.length]);

  const lastUpdatedString = bloodPressure?.fulfilledTimeStamp
    ? "Last Updated: " +
      DateTime.fromMillis(bloodPressure?.fulfilledTimeStamp).toFormat(
        "MM/dd/yyyy hh:mm a ZZZZ"
      )
    : undefined;

  return (
    <Column gap="0px">
      <ChartControls
        readingType={DeviceTrendParam.PULSE}
        handleDataChange={handleDataChange}
        defaultDays={defaultDays}
        member_id={patient?.patient?.patient_id}
        serialNumbers={serialNumbers}
      >
        <AvgTextContainer $visible={showAverages} margin="8px 0">
          <>
            {data?.systolicAvg && (
              <ChartSubHeaderText $color={"#1f77b4"}>
                Pulse Average: {data?.pulseAvg}
              </ChartSubHeaderText>
            )}
          </>
        </AvgTextContainer>
        <br />
        <Flexbox flexDirection="row" ml={"25%"} mr={"10px"}>
          <ThreeLinesContainer
            $showTable={showTable}
            aria-label="tableIcon"
            onClick={toggleTable}
          >
            <ThreeLinesSVG $showTable={showTable} />
          </ThreeLinesContainer>
          <ChartIconContainer
            $showTable={showTable}
            aria-label="gridIcon"
            onClick={toggleTable}
          >
            <ChartIconSVG $showTable={showTable} />
          </ChartIconContainer>
        </Flexbox>
      </ChartControls>
      <PulseChartContainer>
        {bloodPressure?.fetching && <LoadingFallback delay={50} />}
        {bloodPressure?.data ? (
          showTable ? (
            <BloodPressureTable
              bloodPressureData={
                deviceIds?.length > 0
                  ? bloodPressure.data.filter((item) =>
                      deviceIds.includes(item.device_id)
                    )
                  : bloodPressure.data
              }
              patientTimezone={patientTimezone}
            />
          ) : (
            <div id="bloodPressurePulseChart" />
          )
        ) : (
          !bloodPressure?.fetching &&
          bloodPressure?.error && (
            <div style={{ margin: "0 36px" }}>
              <ErrorComponent error={bloodPressure?.error} />
            </div>
          )
        )}
      </PulseChartContainer>
      <Flexbox justifyContent="space-between">
        <Typography variant="body1">{lastUpdatedString}</Typography>
        {canDownloadChart(currentRole) && (
          <Button
            variant="outlined"
            onClick={() =>
              navigate(
                `/reports/${ReportEnum.MEMBER_CHART_SHARE}/memberId/${patient.patient.patient_id}`
              )
            }
            startIcon={<FileDownloadOutlined />}
          >
            Download
          </Button>
        )}
      </Flexbox>
    </Column>
  );
};

export default PulseChart;
