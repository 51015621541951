import { Box } from "@mui/material";
import Tasks from "../../pages/Tasks/Tasks";
import { Flexbox } from "../../styling/NewStyleComponents";
import NewScheduleToday from "../ScheduleToday/NewScheduleToday";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { DateTime } from "luxon";
import {
  checkIdValid,
  getNameOrUsername,
  isFalsy
} from "common/helpers/helpers";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import ErrorComponent from "../ErrorComponent";
import { css } from "@emotion/react";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import useGetCalendarVisits from "../../hooks/data_loaders/useGetCalendarVisits";

import Papa from "papaparse";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import {
  DAYS_TO_LOOK_AHEAD,
  DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING
} from "../../pages/MemberDetails/Appointments/constants";
import { hasProviderRole, isProviderRole } from "common/enums/RolesEnum";

interface CSVData {
  [key: string]: string;
}

function parseCSVData(
  stringData: string,
  callbackFn: Dispatch<SetStateAction<any>>
) {
  Papa.parse(stringData, {
    download: false,
    complete: function (input) {
      const result = input?.data;
      const resultMap = {};
      result.forEach((row) => {
        resultMap[row[0]] = row[1];
      });
      callbackFn(resultMap);
    }
  });
}

const SHOW_TARGET_MINS = true;

function NewScheduleToDoContainer({
  hasRightComponent = false,
  nurseId: propsNurseId,
  isDashboard = false
}: {
  hasRightComponent?: boolean;
  nurseId?: string;
  isDashboard?: boolean;
}) {
  const [fetchedCSVData, setFetchedCSVData] = useState<CSVData>();
  const [isFetchingCSVData, setIsFetchingCSVData] = useState<boolean>(false);

  useEffect(() => {
    if (SHOW_TARGET_MINS && !isFetchingCSVData && !fetchedCSVData) {
      setIsFetchingCSVData(true);
      fetch(
        // let's update the data file to include the date for cache busting purposes
        `${process.env.PUBLIC_URL}/data/2025-01-26-${process.env.REACT_APP_STACK_DEPLOYMENT_ENV}patientencounterdata.csv`
      )
        .then((res) => res.text())
        .then((stringData) => {
          setIsFetchingCSVData(false);
          parseCSVData(stringData, setFetchedCSVData);
        })
        .catch((error) => {
          setIsFetchingCSVData(false);
          console.error(error);
        });
    } else {
      setFetchedCSVData({});
    }
  }, []);
  const { user, currentRole } = useSelector((state: RootState) => state.auth);

  const { nurseId: paramsCarerId } = useSanitizedParams();

  const nurseId = paramsCarerId || propsNurseId;

  const {
    data: carerData,
    isFetching: carerDataIsFetching,
    error: carerError
  } = useGetUserWithUsernameQuery(
    {
      username: nurseId,
      linked_entities: [UserLinkedEntitiesEnum.SCHEDULE_TYPE]
    },
    { skip: isFalsy(nurseId) || !checkIdValid(nurseId) }
  );

  const { data: authenticatedUser } = useGetAuthenticatedUser({
    skip: checkIdValid(nurseId),
    linkedEntities: [UserLinkedEntitiesEnum.SCHEDULE_TYPE]
  });

  const startdate = DateTime.utc().startOf("day").minus({ days: 14 });
  const enddate = useMemo(() => {
    if (
      (isDashboard && isProviderRole(currentRole)) ||
      hasProviderRole(carerData?.user?.roles)
    ) {
      return DateTime.utc()
        .startOf("day")
        .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING - 14 });
    }
    return DateTime.utc()
      .startOf("day")
      .plus({ days: DAYS_TO_LOOK_AHEAD - 14 });
  }, [
    currentRole,
    isDashboard,
    carerData,
    DAYS_TO_LOOK_AHEAD,
    DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING
  ]);

  const {
    data: calendarVisitsData,
    recurringEventsWeekdaysMap,
    isFetching: calendarVisitsIsFetching,
    error: calendarVisitsError,
    refetch: refetchCalendarVisits
  } = useGetCalendarVisits({
    staff_id: nurseId ?? user?.user_id,
    startdate,
    enddate,
    currentRole,
    skip: isFalsy(nurseId ?? user?.user_id)
  });

  const finalCalendarVisitsData = useMemo(() => {
    if (calendarVisitsData?.length && fetchedCSVData) {
      return calendarVisitsData.map((item) => {
        let attendee = item?.attendees?.[0];
        let recommended_encounter_minutes;
        if (fetchedCSVData?.[attendee?.attendee_id]) {
          recommended_encounter_minutes =
            fetchedCSVData?.[attendee?.attendee_id];
        }
        return {
          ...item,
          attendees: [
            {
              ...attendee,
              ...(recommended_encounter_minutes && {
                recommended_encounter_minutes
              })
            }
          ]
        };
      });
    } else {
      return [];
    }
  }, [calendarVisitsData, fetchedCSVData]);

  const finalCarerData = useMemo(() => {
    return carerData ?? authenticatedUser;
  }, [carerData, authenticatedUser]);

  const isFetching = useMemo(() => {
    return calendarVisitsIsFetching || carerDataIsFetching || isFetchingCSVData;
  }, [calendarVisitsIsFetching, carerDataIsFetching, isFetchingCSVData]);

  return (
    <Flexbox
      flexDirection="row"
      margin="28px 2.5% 8px"
      width="95%"
      gap="12px"
      css={
        hasRightComponent
          ? css`
              flex-wrap: nowrap;
              @media (max-width: 1160px) {
                flex-wrap: wrap;
              }
            `
          : css`
              flex-wrap: nowrap;
            `
      }
    >
      <Box
        css={
          // if we show the to-do container, we want to show the calendar on the left side for larger screens
          hasRightComponent
            ? css`
                flex-basis: 50%;
                @media (max-width: 1160px) {
                  flex-basis: 100%;
                }
              `
            : css`
                flex-basis: 100%;
              `
        }
        minWidth={"586px"}
      >
        {isFetching && <LoadingFallback count={15} />}
        {finalCalendarVisitsData && !isFetching && fetchedCSVData && (
          <NewScheduleToday
            selectedCarerId={nurseId}
            refetchCalendarVisits={refetchCalendarVisits}
            calendarEventsData={finalCalendarVisitsData}
            recurringEventsWeekdaysMap={recurringEventsWeekdaysMap}
            startdate={startdate}
            enddate={enddate}
            currentUserId={user?.user_id}
            carerScheduleType={finalCarerData?.schedule_type}
            carerTimezone={finalCarerData?.user?.timezone}
            carerName={getNameOrUsername(finalCarerData?.user)}
            carerRoles={finalCarerData?.user?.roles}
            carerError={carerError}
            isDashboard={isDashboard}
          />
        )}
        {calendarVisitsError && <ErrorComponent error={calendarVisitsError} />}
      </Box>
      <Box
        css={
          hasRightComponent
            ? css`
                flex-basis: 50%;
                @media (max-width: 1160px) {
                  flex-basis: 100%;
                }
              `
            : css`
                flex-basis: 0%;
              `
        }
      >
        {hasRightComponent && (
          <Tasks
            outerContainerMargin="0"
            tasksStatuses={[TaskStatusEnum.TODO, TaskStatusEnum.IN_PROGRESS]}
            componentHeader="To Dos"
            customTasksContainerHeight="508px"
            showWhenNoTasks={true}
            noTasksMessage={
              nurseId
                ? "Nurse has no pending tasks that require imminent action."
                : "You have no pending tasks that require imminent action."
            }
            showResolveButton={false}
            showActionButtons={true}
            tooltipContent="Important and urgent tasks are shown in this module."
            componentHeaderMarginBottom="12px"
            link={
              nurseId
                ? // don't show completed to-dos when viewing another nurse's report
                  null
                : {
                    text: "View Completed",
                    route: "/my-completed-todos"
                  }
            }
          />
        )}
      </Box>
    </Flexbox>
  );
}

export default NewScheduleToDoContainer;
