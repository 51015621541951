import { useState } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { Button, Typography, styled } from "@mui/material";

import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { RootState } from "common/redux";
import RolesEnum, {
  canCreatePastEncounter,
  canSeeEncounterProviderDetails
} from "common/enums/RolesEnum";

import MemberDetailsHeader from "./Header/MemberDetailsHeader";
import MemberDetailsLayout from "./MemberDetailsLayout";
import { Flexbox } from "../../styling/NewStyleComponents";

import Table from "../../components/Table/Table";
import AddPastEncounterModal from "../PatientData/AddPastEncounterModal";
import { checkIdValid, isFalsy } from "common/helpers/helpers";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import ErrorComponent from "../../components/ErrorComponent";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import DeleteEncounterConfirmationModal from "./DeleteEncounterConfirmationModal";
import { SubmitEncounterModal } from "./Header/SubmitEncounter/SubmitEncounterModal";
import { NewProviderEncounterReasons } from "common/helpers/EncounterHelper";
import { useNavigate } from "react-router-dom";

// old endpoint
import { useGetEncountersQuery } from "common/services/EncountersService";
import EncounterLinkedEntitiesEnum from "common/enums/EncounterLinkedEntitiesEnum";
import DropdownDateRangePicker from "../../components/DropdownDateRangePicker";
import MemberType from "common/types/MemberType";
import { NewSubmitEncounterModal } from "./Header/SubmitEncounter/NewSubmitEncounterModal";
import NewAddPastEncounterModal from "../PatientData/NewAddPastEncounterModal";
import FeatureFlags from "common/config/FeatureFlags";
import { TableColumn } from "../../components/Table/TableTypes";

// new endpoint
// import { useGetEncounterVisitsQuery } from "common/services/VisitsService";

const MemberDetailsEncounterContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EncountersTitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export function getEncounterColumns(
  currentRole: RolesEnum,
  isMemberProfile: boolean
) {
  const arr: TableColumn[] = [{ name: "encounterType", size: 220 }];

  if (FeatureFlags().TN_ENCOUNTER_EDIT_DELETE) {
    arr.push({
      name: "encounterDurationEditableNew",
      size: 70,
      editable: true
    });
  } else {
    arr.push({ name: "encounterDurationEditable", size: 70, editable: true });
  }

  arr.push({ name: "encounterDateV2", size: 70 });
  if (isMemberProfile) {
    arr.push({ name: "encounterSubmitterV2", size: 180 });
  } else {
    arr.push({ name: "encounterMember", size: 180 });
  }

  if (canSeeEncounterProviderDetails(currentRole)) {
    arr.push({
      name: "encounterModality",
      size: 65
    });
    arr.push({
      name: "encounterComplexity",
      size: 80
    });
    arr.push({
      name: "encounterVideoCallDeclineReason",
      size: 90
    });
  }

  arr.push({ name: "encounterActions", size: 120 });

  return arr;
}

export const EncountersTable = ({
  member,
  hideDeleteEdit = false
}: {
  member: MemberType;
  hideDeleteEdit?: boolean;
}) => {
  const { currentRole, user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const [selectedEncounterId, setSelectedEncounterId] = useState<string>("");
  const [startsAfter, setStartsAfter] = useState<DateTime>(
    DateTime.now().minus({ days: 7 }).startOf("day")
  );
  const [startsBefore, setStartsBefore] = useState<DateTime>(
    DateTime.now().endOf("day")
  );
  const [editPastEncounterModalVisible, setEditPastEncounterModalVisible] =
    useState<boolean>(false);
  const [addPastEncounterModalVisible, setAddPastEncounterModalVisible] =
    useState<boolean>(false);
  const [isDeleteEncounterModalOpen, setIsDeleteEncounterModalOpen] =
    useState<boolean>(false);
  const closeDeleteEncounterModal = () => {
    setIsDeleteEncounterModalOpen(false);
    setSelectedEncounterId("");
  };

  const [selectedEncounter, setSelectedEncounter] = useState(undefined);
  const handleDeleteEncounter = (encounter_id: string) => {
    setSelectedEncounterId(encounter_id);
    setIsDeleteEncounterModalOpen(true);
  };
  const setEditModal = (visible: boolean) => {
    if (!visible) setSelectedEncounter(undefined);
    setEditPastEncounterModalVisible(visible);
  };

  const encounterColumns = getEncounterColumns(currentRole, true);

  const {
    data: encounterVisits,
    isFetching: encounterVisitsIsFetching,
    error: encounterVisitsError
  } = useGetEncountersQuery(
    {
      patient_id: member?.patient?.patient_id,
      startsAfter: startsAfter,
      startsBefore: startsBefore,
      linked_entities: [EncounterLinkedEntitiesEnum.VISIT]
    },
    { skip: isFalsy(member?.patient) }
  );

  return (
    <>
      {canCreatePastEncounter(currentRole) && !hideDeleteEdit && (
        <Button
          variant="outlined"
          onClick={() => setAddPastEncounterModalVisible(true)}
        >
          Add Past Encounter
        </Button>
      )}
      <EncountersTitleContainer>
        <Typography variant="h4" mt="16px">
          Encounters History
        </Typography>

        <DropdownDateRangePicker
          onEndDateChange={setStartsBefore}
          onStartDateChange={setStartsAfter}
          endDate={startsBefore}
          startDate={startsAfter}
          dateRangeDefault="30_days"
        />
      </EncountersTitleContainer>
      {encounterVisits && !encounterVisitsIsFetching && (
        <Table
          tableColumns={encounterColumns}
          data={encounterVisits}
          tableProps={{
            navigate,
            handleDeleteEncounter,
            currentRole,
            currentUserId: user?.user_id,
            setSelectedEncounter,
            setEditPastEncounterModalVisible,
            hideDeleteEdit
          }}
        />
      )}
      {encounterVisitsIsFetching && <LoadingFallback count={10} />}
      {encounterVisitsError && <ErrorComponent error={encounterVisitsError} />}

      {/* We are only using this for NP encounters right now */}
      {!FeatureFlags().NEW_PROVIDER_ENCOUNTER_CHANGES && (
        <SubmitEncounterModal
          key={`editEncounter-${selectedEncounter?.encounter_id}`}
          modalOpen={
            editPastEncounterModalVisible && !isFalsy(selectedEncounter)
          }
          setModalOpen={setEditModal}
          memberId={member?.patient?.patient_id}
          isProvider={true}
          encounterReasons={NewProviderEncounterReasons}
          memberName={selectedEncounter?.member_name}
          default_total_time={selectedEncounter?.total_time}
          default_visit_type={selectedEncounter?.visit_type}
          default_modality={selectedEncounter?.modality}
          default_complexity={selectedEncounter?.complexity}
          selected_encounter_id={selectedEncounter?.encounter_id}
          hasProviderSubmittedEncounter={true}
          hasInvalidProviderAssigned={false}
        />
      )}
      {FeatureFlags().NEW_PROVIDER_ENCOUNTER_CHANGES && (
        <NewSubmitEncounterModal
          key={`neweditEncounter-${selectedEncounter?.encounter_id}`}
          modalOpen={
            editPastEncounterModalVisible && !isFalsy(selectedEncounter)
          }
          setModalOpen={setEditModal}
          memberId={member?.patient?.patient_id}
          isProvider={true}
          encounterReasons={NewProviderEncounterReasons}
          memberName={selectedEncounter?.member_name}
          default_total_time={selectedEncounter?.total_time}
          default_visit_type={selectedEncounter?.visit_type}
          default_modality={selectedEncounter?.modality}
          default_complexity={selectedEncounter?.complexity}
          default_video_call_decline_reason={
            selectedEncounter?.video_call_decline_reason
          }
          selected_encounter_id={selectedEncounter?.encounter_id}
          hasProviderSubmittedEncounter={true}
          hasInvalidProviderAssigned={false}
        />
      )}

      {!FeatureFlags().NEW_PROVIDER_ENCOUNTER_CHANGES && (
        <AddPastEncounterModal
          patient={member}
          isOpen={addPastEncounterModalVisible}
          onRequestClose={() => setAddPastEncounterModalVisible(false)}
          historicalEncounters={encounterVisits}
        />
      )}
      {FeatureFlags().NEW_PROVIDER_ENCOUNTER_CHANGES && (
        <NewAddPastEncounterModal
          patient={member}
          isOpen={addPastEncounterModalVisible}
          onRequestClose={() => setAddPastEncounterModalVisible(false)}
          historicalEncounters={encounterVisits}
        />
      )}

      <DeleteEncounterConfirmationModal
        isOpen={isDeleteEncounterModalOpen}
        onRequestClose={() => closeDeleteEncounterModal()}
        encounterId={selectedEncounterId}
      />
      <br />
    </>
  );
};

const MemberDetailsEncounters = ({
  hideReadings = false
}: {
  hideReadings?: boolean;
}) => {
  const params = useSanitizedParams();
  const { memberId } = params;
  const isValidId = checkIdValid(memberId);

  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: isValidId === false }
  );

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <MemberDetailsEncounterContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-encounters`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-encounters`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <br />
          <EncountersTable member={member} />
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsEncounterContainer>
  );
};

export default MemberDetailsEncounters;
