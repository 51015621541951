import { useEffect, useId, useState } from "react";
import { useGetRegisterStatusQuery } from "common/services/MemberRegistrationService";
import { Field, Form, Formik, useFormikContext } from "formik";
import styled from "@emotion/styled";
import { useInterval } from "common/hooks/useInterval";
import { ErrorText, ExternalLink, Spinner } from "../../styling";
import RegisterConflictStatus from "./enums/RegisterConflictStatus";
import departmentsData from "../../config/departmentsData.json";
import { blue } from "common/styling/colors";

const COMPLETE_SETUP_IN_ATHENA = "Complete Setup in Athena";
const VIEW_PATIENT_IN_ATHENA = "View Member in Athena";

const StyledSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
  left: 0px;
  top: 12px;
`;

const PollingHeading = styled.div`
  font-size: 16px;
`;

const SpinnerContainer = styled.div`
  position: relative;
`;

const RadioButtonGroup = styled.div`
  margin-top: 24px;
`;

const RadioButtonContainer = styled.label<{ $active?: boolean }>`
  background: ${(props) => (props?.$active ? blue[50] : "#fff")};
  border-radius: 4px;
  width: 90%;
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: all 0.66s ease-out;
`;

const RadioButton = styled(Field)`
  margin: 8px;
  background: ${blue[700]};
`;

const RadioButtonText = styled.div<{ $active?: boolean }>`
  color: ${(props) =>
    props?.$active ? blue[700] : props?.theme?.color?.darkGrey};
  font-weight: ${(props) => (props?.$active ? "700" : "400")};
`;

const SearchResultHeading = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${blue[700]};
`;

const PatientInfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${blue[700]};
  text-decoration: underline;
`;

interface PollingComponentFormValues {
  selectedAthenaId: string;
}

const FormikListener = ({ setSelectedAthenaLinkCallback }) => {
  // Grab values and submitForm from context
  const { values } = useFormikContext<PollingComponentFormValues | undefined>();

  useEffect(() => {
    // Update parent component state with the selected option
    if (values?.selectedAthenaId) {
      setSelectedAthenaLinkCallback(values?.selectedAthenaId);
    }
  }, [values, setSelectedAthenaLinkCallback]);
  return null;
};

const SelectAthenaIdForm = ({ athenaLinks, setSelectedAthenaLinkCallback }) => {
  const dataKey = "selectedAthenaId";
  const id = useId();
  return (
    <>
      <Formik
        validate={() => {}}
        initialValues={{
          selectedAthenaId: ""
        }}
        onSubmit={() => {}}
      >
        {() => (
          <>
            <SearchResultHeading>
              Multiple Athena IDs found, please select the right one to
              register.
            </SearchResultHeading>
            <Form>
              <FormikListener
                setSelectedAthenaLinkCallback={setSelectedAthenaLinkCallback}
              />
              <RadioButtonGroup role="group" aria-labelledby={dataKey}>
                {athenaLinks?.map((athenaLink) => {
                  const athenaId = athenaLink.athena_id;
                  const departmentId = athenaLink.department_id;
                  const providerId = athenaLink.provider_id;
                  const link = athenaLink.link;

                  const providerDetails = departmentsData.find(
                    (provider) => provider.ProviderID === Number(providerId)
                  );

                  const value = `${athenaId}-${providerId}-${departmentId}`;

                  return (
                    <RadioButtonContainer key={`${id}-${athenaId}`}>
                      <RadioButton type="radio" name={dataKey} value={value} />
                      <RadioButtonText>
                        <ExternalLink href={`${link}`} target="_blank">
                          <PatientInfoText>
                            Athena ID: {athenaId}{" "}
                            {providerDetails
                              ? `Provider: ${providerDetails.Name}`
                              : ""}
                          </PatientInfoText>
                        </ExternalLink>
                      </RadioButtonText>
                    </RadioButtonContainer>
                  );
                })}
              </RadioButtonGroup>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export function PollingComponent({
  isPolling,
  leadsId,
  patientName,
  setIsPollingCallback,
  setAthenaLinkCallback,
  setSelectedAthenaLinkCallback,
  setHasMultipleAthenaLinksCallback,
  setAthenaButtonTextCallback
}) {
  const DEFAULT_POLLING_MESSAGE = "Processing your request…";
  const REGISTERED_SUCCESSFULLY_MESSAGE = `Successfully created member ${patientName}.`;
  const DUPLICATE_MESSAGE = `Member ${patientName} already exists.`;
  const TIMED_OUT_MESSAGE = "Error: Request timed out after 90 seconds.";
  const MULTIPLE_BEST_MATCHES_MESSAGE = `Multiple Athena accounts found for ${patientName}.`;
  const ATHENA_SALESFORCE_DO_NOT_MATCH_MESSAGE = `Found member in Athena, but names do not match. Please fix appropriately and try again.`;
  const NEW_LEAD_EXISTING_PATIENT_MESSAGE = `Member ${patientName} was previously registered.`;
  const INVALID_BIRTH_DATE_MESSAGE = `Member birth date is invalid. Please fix in Salesforce and try again.`;
  const INVALID_DATA_IN_SALESFORCE_LEAD_MESSAGE = `Athena rejected the member info for ${patientName}. Please validate the data (phone numbers, address) in Salesforce and try again.`;
  const INVALID_PROVIDER_DEPARTMENT_IN_ATHENA_MESSAGE = `Member has invalid department or provider assignment in Athena. Please fix in Athena and try again.`;

  const REFRESH_CHECK_VARIABLE = 1000;
  const [shouldFinishPolling, setShouldFinishPolling] = useState(false);
  const [pollingCount, setPollingCount] = useState(0);
  const [pollingMessage, setPollingMessage] = useState(DEFAULT_POLLING_MESSAGE);
  const [athenaLinks, setAthenaLinks] = useState(null);
  const [dataError, setDataError] = useState(null);

  const res = useGetRegisterStatusQuery(leadsId, {
    pollingInterval: REFRESH_CHECK_VARIABLE,
    skip: !isPolling || shouldFinishPolling,
    // never cache
    refetchOnMountOrArgChange: true
  });

  const response = res?.data;
  const error = res?.error;

  useInterval(async () => {
    if (response === undefined) return;
    // If we do not receive a success message after 90 requests to /status, display timed out message.
    if (pollingCount > 90) {
      setIsPollingCallback(false);
      setPollingMessage(TIMED_OUT_MESSAGE);
    }
    if (isPolling && !shouldFinishPolling) {
      setPollingCount(pollingCount + 1);

      const state = response?.state;
      const returnCode = response?.return_code;
      let athenaLink: string[] | boolean = response?.athena_links;
      const leadsStatusMessage = response?.leads_status_message;
      let leadsStatusMessageJSON,
        errorCode,
        errorMessage,
        conflictCode,
        conflictMessage;

      if (leadsStatusMessage) {
        // in case this is not valid JSON
        try {
          leadsStatusMessageJSON = JSON.parse(leadsStatusMessage);
        } catch (e) {
          // do nothing
        }
      }

      // Attempt to parse for error codes
      if (leadsStatusMessageJSON?.error_code) {
        errorCode = leadsStatusMessageJSON?.error_code;
      }
      if (leadsStatusMessageJSON?.error_message) {
        errorMessage = leadsStatusMessageJSON?.error_message;
        try {
          errorMessage = JSON.parse(errorMessage);
        } catch (e) {
          // do nothing
        }
      }

      // Attempt to parse for conflict codes
      if (leadsStatusMessageJSON?.conflict_code) {
        conflictCode = leadsStatusMessageJSON?.conflict_code;
      }
      if (leadsStatusMessageJSON?.conflict_message) {
        conflictMessage = leadsStatusMessageJSON?.conflict_message;
        try {
          conflictMessage = JSON.parse(conflictMessage);
        } catch (e) {
          // do nothing
        }
      }

      // @ts-ignore
      const errorState = error?.data?.state;

      if (state === "completed") {
        setShouldFinishPolling(true);
        setIsPollingCallback(false);
        // If returnCode is 409, handle expected conflict events
        if (returnCode === 409) {
          setHasMultipleAthenaLinksCallback(
            conflictCode === RegisterConflictStatus.MULTIPLE_BEST_MATCHES
          );
          if (conflictCode === RegisterConflictStatus.MULTIPLE_BEST_MATCHES) {
            setPollingMessage(MULTIPLE_BEST_MATCHES_MESSAGE);
            setAthenaLinks(conflictMessage);
          } else if (
            conflictCode ===
            RegisterConflictStatus.ATHENA_SALESFORCE_DO_NOT_MATCH
          ) {
            setPollingMessage(ATHENA_SALESFORCE_DO_NOT_MATCH_MESSAGE);
            setDataError(conflictMessage);
            athenaLink = conflictMessage.link;
            setAthenaButtonTextCallback(VIEW_PATIENT_IN_ATHENA);
          } else if (
            conflictCode === RegisterConflictStatus.NEW_LEAD_EXISTING_PATIENT
          ) {
            setPollingMessage(NEW_LEAD_EXISTING_PATIENT_MESSAGE);
            setAthenaLinks(conflictMessage);
            athenaLink = conflictMessage.link;
            setAthenaButtonTextCallback(VIEW_PATIENT_IN_ATHENA);
          } else if (
            conflictCode === RegisterConflictStatus.INVALID_BIRTH_DATE
          ) {
            setPollingMessage(INVALID_BIRTH_DATE_MESSAGE);
            athenaLink = conflictMessage.link;
            setAthenaButtonTextCallback(VIEW_PATIENT_IN_ATHENA);
          } else if (
            conflictCode ===
            RegisterConflictStatus.INVALID_DATA_IN_SALESFORCE_LEAD
          ) {
            setPollingMessage(INVALID_DATA_IN_SALESFORCE_LEAD_MESSAGE);
            athenaLink = false;
            setAthenaButtonTextCallback(COMPLETE_SETUP_IN_ATHENA);
          } else if (
            conflictCode ===
            RegisterConflictStatus.INVALID_PROVIDER_DEPARTMENT_IN_ATHENA
          ) {
            setPollingMessage(INVALID_PROVIDER_DEPARTMENT_IN_ATHENA_MESSAGE);
            athenaLink = false;
          } else {
            setPollingMessage(DUPLICATE_MESSAGE);
            setAthenaButtonTextCallback(VIEW_PATIENT_IN_ATHENA);
          }
        } else {
          setPollingMessage(REGISTERED_SUCCESSFULLY_MESSAGE);
          setAthenaButtonTextCallback(COMPLETE_SETUP_IN_ATHENA);
        }
      } else if (error && "status" in error && error?.status === 500) {
        let pollingMessage = `Unexpected error while processing your request, please try again or contact your administrator. Status code: ${error?.status}`;
        if ("message" in error && error?.message) {
          pollingMessage += error?.message;
        }
        setShouldFinishPolling(true);
        setIsPollingCallback(false);
        setPollingMessage(pollingMessage);
      } else if (state === "failed" || errorState === "failed") {
        let pollingMessage = "Error registering patient in Athena.";
        if (errorMessage) {
          if (typeof errorMessage === "string") {
            pollingMessage = `Unexpected Error: ${errorMessage}`;
          } else if (typeof errorMessage?.error_message === "string") {
            pollingMessage = `Unexpected Error: ${errorMessage.error_message}`;
          } else {
            pollingMessage = `Unexpected Error: ${JSON.stringify(
              errorMessage
            )}`;
          }
        }
        setShouldFinishPolling(true);
        setIsPollingCallback(false);
        setPollingMessage(pollingMessage);
      }

      if (athenaLink && !dataError) {
        setAthenaLinkCallback(athenaLink);
      }
    }
  }, REFRESH_CHECK_VARIABLE);

  return (
    <>
      <PollingHeading>{pollingMessage}</PollingHeading>
      {athenaLinks?.length > 1 && (
        <SelectAthenaIdForm
          athenaLinks={athenaLinks}
          setSelectedAthenaLinkCallback={setSelectedAthenaLinkCallback}
        />
      )}
      {dataError && (
        <div>
          <ErrorText>Salesforce: {dataError.salesforce_name}</ErrorText>
          <ErrorText>Athena: {dataError.athena_name}</ErrorText>
        </div>
      )}
      {isPolling && !shouldFinishPolling && (
        <SpinnerContainer>
          &nbsp;
          <StyledSpinner loading="true" />
        </SpinnerContainer>
      )}
    </>
  );
}
