import {
  Dispatch,
  forwardRef,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useId,
  useState,
  ChangeEvent
} from "react";
import { Formik, Form, useFormikContext, Field, FormikProps } from "formik";
import styled from "@emotion/styled";
import { Radio, RadioGroup } from "@mui/material";

import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import MemberType from "common/types/MemberType";
import RolesEnum from "common/enums/RolesEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

import { ErrorText } from "../../../styling";
import { ArrowBackIcon } from "../../../assets/images/icons";
import SearchPatientForm, { SearchPatientFormType } from "./SearchPatientForm";
import { StatusBadge } from "../../../styling/StyleComponents";
import CopyPatientLinkToClipboard from "../../../styling/CopyPatientLinkToClipboard";
import { blue } from "common/styling/colors";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { MEMBERS_PATH } from "../../../routes/RoutePaths";
import ErrorType from "common/types/ErrorType";

const SearchPatientStepsContainer = styled.div``;

const BackContainer = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.color.darkGrey};
  display: flex;
  margin-bottom: 18px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  margin-top: 24px;
`;

const RadioButtonContainer = styled.label<{ $active?: boolean }>`
  background: ${(props) => (props?.$active ? "#e5f8f9" : "#fff")};
  border-radius: 4px;
  width: 75%;
  min-height: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: all 0.66s ease-out;
`;

const RadioButtonText = styled.div<{ $active?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  color: ${(props) =>
    props?.$active ? blue[700] : props?.theme?.color?.darkGrey};
  font-weight: ${(props) => (props?.$active ? "700" : "400")};
  max-width: 50%:
`;

const SearchResultHeading = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${blue[700]};
`;

const PatientInfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.color?.mediumDarkGrey};
`;

const ModalSuccessMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: rgba(24, 24, 25, 0.9);

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

interface FormikFormFields {
  selectedUsername: string;
}

interface FormikListenerProps {
  patientList: MemberType[];
  setSelectedSearchIdCallback: (user_id: string) => void;
}

const FormikListener = ({
  patientList,
  setSelectedSearchIdCallback
}: FormikListenerProps) => {
  // Grab values and submitForm from context
  const { values } = useFormikContext<FormikFormFields>();

  useEffect(() => {
    // Update parent component state with the selected option
    if (values?.selectedUsername) {
      const selectedUserInfo = patientList.find(
        (item) => item.patient.username === values?.selectedUsername
      );
      const { patient_id } = selectedUserInfo?.patient || {};
      setSelectedSearchIdCallback(patient_id!);
    }
  }, [values, setSelectedSearchIdCallback, patientList]);
  return null;
};

interface GetUserMatchesIProps {
  patientList: MemberType[];
  setSelectedSearchIdCallback: (patient_id: string) => void;
  disableSelection: boolean;
}

const GetUserMatchesForm = ({
  patientList,
  setSelectedSearchIdCallback,
  disableSelection
}: GetUserMatchesIProps) => {
  const dataKey = "selectedUsername";
  const id = useId();

  return (
    <>
      <Formik
        validate={() => {}}
        initialValues={{
          selectedUsername: ""
        }}
        onSubmit={() => {}}
      >
        {() => (
          <Form data-testid="SearchPatientSteps-results">
            <FormikListener
              patientList={patientList}
              setSelectedSearchIdCallback={setSelectedSearchIdCallback}
            />

            <Field name={dataKey}>
              {({ field, form }) => (
                <StyledRadioGroup
                  aria-labelledby={dataKey}
                  role="group"
                  value={field.value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    form.setFieldValue(dataKey, event.target.value);
                  }}
                >
                  {patientList?.map((patient) => {
                    const { username, birthdate, status } = patient.patient;
                    const memberId = patient?.patient?.patient_id;

                    return (
                      <RadioButtonContainer key={`${id}-${username}`}>
                        {disableSelection === false && (
                          <Radio name={dataKey} value={username} />
                        )}
                        <RadioButtonText>
                          <SearchResultHeading>
                            <Flexbox flexDirection="row">
                              {getNameOrUsername(patient.patient)}

                              {memberId && (
                                <CopyPatientLinkToClipboard
                                  memberId={memberId}
                                  customLink={MEMBERS_PATH}
                                />
                              )}
                            </Flexbox>
                          </SearchResultHeading>
                          <PatientInfoText>{birthdate}</PatientInfoText>
                        </RadioButtonText>
                        {status && (
                          <div style={{ width: "110px" }}>
                            <StatusBadge status={status} />
                          </div>
                        )}
                      </RadioButtonContainer>
                    );
                  })}
                </StyledRadioGroup>
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </>
  );
};

const displayUserMatches = (
  response: MemberType[],
  setSelectedSearchIdCallback: (user_id: string) => void,
  disableSelection: boolean
) => {
  if (response && response?.length > 0) {
    return (
      <>
        <div>
          The following members were found
          {isFalsy(disableSelection) ?? ", please select the best option"}:
        </div>
        <GetUserMatchesForm
          patientList={response}
          setSelectedSearchIdCallback={setSelectedSearchIdCallback}
          disableSelection={disableSelection}
        />
      </>
    );
  }

  if (response?.length === 0) {
    return (
      <>
        <ModalSuccessMessage>No matches found!</ModalSuccessMessage>
      </>
    );
  }

  return <ErrorText>Something went wrong. Please try again later.</ErrorText>;
};

interface SearchPatientIProps {
  searchPatientStep: number;
  setSearchPatientStepCallback: (number: number) => void;
  setSelectedSearchIdCallback: (patient_id: string | undefined) => void;
  updateSelectPatientFormDirty: (dirty: boolean) => void;
  disableSelection: boolean;
  resetSearchState: () => void;
  setSelectedUserCallback: (patient_id: string) => void;
  onSubmitProps?: {
    userListFilters: {
      roles?: RolesEnum[];
      status?: MemberStatusEnum[];
      linked_entities?: MemberLinkedEntitiesEnum[];
    };
  };
  dataLoadingProps?: {
    setDataCallback?: (users: MemberType[] | undefined) => void;
    setDataLoadingCallback?: (isDataLoading: boolean) => void;
    setDataErrorCallback?: (error: ErrorType) => void;
  };
  searchPatientLoading: boolean;
  setSearchPatientLoading: Dispatch<SetStateAction<boolean>>;
}

// TBD refactor this component to make it more extensible and comprehensive
const SearchPatientSteps = forwardRef(
  (
    props: SearchPatientIProps,
    ref: Ref<FormikProps<SearchPatientFormType>>
  ) => {
    const {
      searchPatientStep,
      setSearchPatientStepCallback,
      setSelectedSearchIdCallback,
      updateSelectPatientFormDirty,
      disableSelection = false,
      onSubmitProps,
      resetSearchState,
      dataLoadingProps = {},
      setSearchPatientLoading
    } = props;
    const [searchPatientResults, setSearchPatientResults] =
      useState<MemberType[]>();

    const setSearchPatientResultsCallback = useCallback(
      ({ results }: { results: MemberType[] }) => {
        setSearchPatientResults(results);
      },
      [setSearchPatientResults]
    );

    return (
      <SearchPatientStepsContainer>
        {searchPatientStep === 0 && (
          <SearchPatientForm
            ref={ref}
            resetSearchState={resetSearchState}
            setSearchPatientStepCallback={setSearchPatientStepCallback}
            setSearchPatientResultsCallback={setSearchPatientResultsCallback}
            updateSelectPatientFormDirty={updateSelectPatientFormDirty}
            dataLoadingProps={dataLoadingProps}
            onSubmitProps={onSubmitProps}
            setSearchPatientLoading={setSearchPatientLoading}
          />
        )}
        {searchPatientStep === 1 && (
          <>
            <BackContainer
              onClick={() => {
                setSearchPatientStepCallback(0);
                setSelectedSearchIdCallback(undefined);
              }}
            >
              <ArrowBackIcon />
              Search again
            </BackContainer>
            {searchPatientResults &&
              displayUserMatches(
                searchPatientResults,
                setSelectedSearchIdCallback,
                disableSelection
              )}
          </>
        )}
      </SearchPatientStepsContainer>
    );
  }
);

export default SearchPatientSteps;
