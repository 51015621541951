import { isFalsy } from "common/helpers/helpers";
import {
  useGetGoalsQuery,
  useUpsertGoalAssessmentsMutation,
  useUpsertGoalsMutation
} from "common/services/MemberGoalsRelationshipService";
import ErrorComponent from "../../../components/ErrorComponent";
import {
  Box,
  Button,
  Container,
  MenuItem,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { blue, gray } from "common/styling/colors";
import { Circle, Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import AddGoalModal from "../../../components/Modal/AddGoalModal";
import {
  getGoalTitleByCategory,
  getPrettyGoalAssessmentStatus,
  GoalAssessmentStatusEnum,
  GoalStatusEnum,
  GoalType,
  GoalTypeEnum
} from "common/types/GoalType";
import { StatusBadge } from "../../../styling/StyleComponents";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { Flexbox } from "../../../styling/NewStyleComponents";
import Table from "../../../components/Table/Table";
import StyledIconButton from "../../../components/Button/StyledIconButton";
import { red } from "@mui/material/colors";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { dispatch } from "common/redux";
import MemberType from "common/types/MemberType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { CarePlanConditionsEnum } from "common/types/Visits/CarePlanType";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import VisitContentField from "./VisitContentField";
import CareFlowFieldTypeEnum from "common/enums/CareFlowFieldTypeEnum";

const GridContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Row = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IProps {
  memberId: string;
  goalOnly?: boolean; // Whether we only want to show the goal and not the "progress to goal" tab
  clinicalOnly?: boolean; // Whether the control should only show clinical goals
  condensed?: boolean; // Whether to show the title (Goals) and description (SMART Goals)
}

interface ProcessedGoalsType {
  clinical: GoalType[];
  member: GoalType[];
}

interface FormType {
  assessment_status?: string;
  current_value?: string;
  notes?: string;
}

const ASSESMENT_VALUES = Object.keys(GoalAssessmentStatusEnum);

const RenderGoal = ({
  goal,
  memberId,
  goalOnly = false,
  onEditPressed
}: {
  goal: GoalType;
  memberId: string;
  goalOnly: boolean;
  onEditPressed: (goal: GoalType) => void;
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const [upsertMutation, { isLoading, isSuccess }] =
    useUpsertGoalAssessmentsMutation();

  const [upsertGoalsMutation] = useUpsertGoalsMutation();

  const formik = useFormik<FormType>({
    initialValues: {
      assessment_status: undefined,
      current_value: "",
      notes: ""
    },
    validate: (values) => {
      const errors = {};
      if (!values.assessment_status) {
        errors["assessment_status"] = "Mandatory field";
      }

      if (!values.current_value) {
        errors["current_value"] = "Mandatory field";
      }
      return errors;
    },
    onSubmit: (values) => {
      upsertMutation({
        goalAssessments: [
          {
            goal_id: goal.goal_id,
            assessment_status: values.assessment_status,
            current_value: values.current_value,
            notes: values.notes
          }
        ],
        member_id: memberId
      });
      formik.resetForm();
    },
    enableReinitialize: true
  });

  const columns = [
    { name: "goalAssessmentAssessmentStatus", size: 100 },
    { name: "goalAssessmentCurrentValue", size: 100 },
    { name: "goalAssessmentNotes", size: 300 },
    { name: "goalAssessmentAssessedOn", size: 100 }
  ];

  enum TabPanelIndex {
    newAssessment = 0,
    latestAssessments = 1
  }

  const renderTabIndex = (index) => {
    return (
      <>
        {index === TabPanelIndex.latestAssessments && (
          <Row>
            {!latest_assessments ||
              (latest_assessments?.length === 0 && (
                <Typography
                  variant="h6"
                  color="text.secondary"
                  textAlign={"center"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"50px"}
                >
                  No assessments for this goal found
                </Typography>
              ))}
            {latest_assessments && latest_assessments?.length > 0 && (
              <Flexbox gap="10px" flexDirection="column" width={"100%"}>
                <Table
                  tableColumns={columns}
                  data={latest_assessments}
                  showTableCount={false}
                />
                <Typography variant={"caption"} textAlign={"right"}>
                  Only the 5 latest progress records will be shown.
                </Typography>
              </Flexbox>
            )}
          </Row>
        )}
        {index === TabPanelIndex.newAssessment && (
          <>
            <br />
            <Row style={{ justifyContent: "flex-start", gap: "20px" }}>
              <Circle
                color={
                  formik.values.assessment_status ===
                    GoalAssessmentStatusEnum.ON_TRACK ||
                  formik.values.assessment_status ===
                    GoalAssessmentStatusEnum.COMPLETED
                    ? "success"
                    : formik.values.assessment_status ===
                        GoalAssessmentStatusEnum.OFF_TRACK
                      ? "error"
                      : "disabled"
                }
              />
              <TextField
                label={"Status"}
                select={true}
                slotProps={{
                  select: {
                    variant: "outlined",
                    MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
                    onChange: (event) => {
                      formik.setFieldValue(
                        "assessment_status",
                        event.target.value
                      );
                    }
                  }
                }}
                value={formik.values.assessment_status ?? ""}
                style={{ width: 200 }}
                error={!isFalsy(formik.errors["assessment_status"])}
                helperText={formik.errors["assessment_status"]}
                placeholder={"Select"}
              >
                {ASSESMENT_VALUES.filter(
                  (item) => item !== GoalAssessmentStatusEnum.NO_DATA
                ).map((item) => (
                  <MenuItem key={item} value={item}>
                    {getPrettyGoalAssessmentStatus(item)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Current Value"
                value={formik.values.current_value ?? ""}
                onChange={(event) =>
                  formik.setFieldValue("current_value", event.target.value)
                }
              />
              <TextField label="Target Value" value={target_value} disabled />
            </Row>
            <br />
            <Row
              style={{
                justifyContent: "flex-start",
                gap: "20px",
                marginLeft: "44px"
              }}
            >
              <TextField
                label="Notes"
                placeholder="Add note"
                multiline={true}
                rows={2}
                fullWidth={true}
                value={formik.values.notes ?? ""}
                onChange={(event) =>
                  formik.setFieldValue("notes", event.target.value)
                }
                error={!isFalsy(formik.errors["notes"])}
                helperText={formik.errors["notes"]}
              />
            </Row>
            <br />
            <Row
              style={{
                display: "flex",
                gap: "20px",
                marginLeft: "44px",
                alignItems: "center",
                marginBottom: "10px"
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={!formik.isValid}
                onClick={() => formik.handleSubmit()}
                loading={isLoading}
              >
                Add progress
              </LoadingButton>

              {isSuccess && (
                <>
                  <br />
                  <Typography
                    variant="body1"
                    color="green"
                  >{`Added progress to goal`}</Typography>
                </>
              )}
              <Box sx={{ alignSelf: "flex-end", gap: 2 }}>
                <StyledIconButton
                  onClick={() => onEditPressed(goal)}
                  Icon={Edit}
                  iconColor={blue[700]}
                  color={"transparent"}
                  size={"small"}
                />
                <StyledIconButton
                  onClick={() => {
                    Alert_show({
                      dispatch,
                      id: "delete_goal",
                      title: "Confirm",
                      content: "Are you sure you want to delete this goal?",
                      size: "small",
                      type: "warning",
                      row: true,
                      buttons: [
                        {
                          text: "Delete",
                          style: "destructive",
                          onPress: () => {
                            upsertGoalsMutation({
                              goals: [
                                {
                                  goal_id: goal.goal_id,
                                  goal_type: goal.goal_type,
                                  goal_category: goal.goal_category,
                                  created_by: goal.created_by,
                                  created_on: goal.created_on,
                                  goal_description: goal.goal_description,
                                  target_value: goal.target_value,
                                  goal_status: GoalStatusEnum.INACTIVE
                                }
                              ],
                              member_id: memberId
                            });
                            Alert_close({ dispatch, id: "delete_goal" });
                          }
                        },
                        {
                          text: "Cancel",
                          style: "cancel",
                          onPress: () => {
                            Alert_close({ dispatch, id: "delete_goal" });
                          }
                        }
                      ]
                    });
                  }}
                  Icon={Delete}
                  iconColor={red[700]}
                  color={"transparent"}
                  size={"small"}
                />
              </Box>
            </Row>
          </>
        )}
      </>
    );
  };

  const { goal_description, goal_category, target_value, latest_assessments } =
    goal;
  return (
    <Container
      sx={{
        background: "white",
        padding: "10px",
        border: 2,
        borderColor: `${gray[300]}`,
        borderRadius: "4px",
        boxShadow: 0,
        maxWidth: "100%"
      }}
    >
      <Row>
        <div>
          <Typography variant="h4" mt={"15px"}>
            {getGoalTitleByCategory(goal_category)}
          </Typography>
          <Typography variant="body1">{goal_description}</Typography>
        </div>
        <StatusBadge status={goal_category} hideDot={true} />
      </Row>
      <br />
      {!goalOnly && (
        <>
          <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            aria-label="Goal Tabs"
          >
            <Tab
              key={TabPanelIndex.newAssessment}
              label={"Update Progress"}
              style={{ fontWeight: "600" }}
            />
            <Tab
              key={TabPanelIndex.latestAssessments}
              label={"Progress to Goal"}
              style={{ fontWeight: "600" }}
            />
          </Tabs>
          {renderTabIndex(tabIndex)}
        </>
      )}
    </Container>
  );
};

export const VisitFieldGoalManagement = ({
  memberId,
  goalOnly = false,
  clinicalOnly = false,
  condensed = false
}: IProps) => {
  const [processedGoals, setProcessedGoals] = useState<ProcessedGoalsType>({
    clinical: [],
    member: []
  });
  const { data, error } = useGetGoalsQuery(
    { member_id: memberId },
    { skip: isFalsy(memberId) }
  );

  const [addGoalModalVisible, setAddGoalModalVisible] =
    useState<boolean>(false);
  const [selectedGoalForEdit, setSelectedGoalForEdit] =
    useState<GoalType>(null);
  const [newGoalType, setNewGoalType] = useState<GoalTypeEnum>(null);

  useEffect(() => {
    const process = async () => {
      const newProcessedGoals: ProcessedGoalsType = {
        clinical: [],
        member: []
      };
      for (const goal of data) {
        if (goal.goal_status === GoalStatusEnum.INACTIVE) continue;
        if (goal.goal_type === GoalTypeEnum.CLINICAL)
          newProcessedGoals.clinical.push(goal);
        else if (!clinicalOnly) newProcessedGoals.member.push(goal);
      }
      setProcessedGoals(newProcessedGoals);
    };

    if (data) process();
  }, [data]);

  const triggerAddGoal = (newGoalType: GoalTypeEnum) => {
    setNewGoalType(newGoalType);
    setAddGoalModalVisible(true);
  };

  const triggerEditGoal = (goal: GoalType) => {
    setAddGoalModalVisible(true);
    setSelectedGoalForEdit(goal);
    setNewGoalType(goal.goal_type);
  };

  return (
    <>
      {!condensed && (
        <>
          <br />
          <Typography variant="h5">GOALS</Typography>
          <br />
          <Typography variant="h6">SMART Goals</Typography>
          <Typography variant="body1">
            SMART goals are Specific, Measurable, Achievable, Relevant, and
            Time-bound. They provide clear objectives and deadlines for
            achievement
          </Typography>
        </>
      )}

      <br />
      <Row>
        <Typography variant="h5" color="primary">
          CLINICAL GOALS
        </Typography>
        <Button
          variant="outlined"
          onClick={() => triggerAddGoal(GoalTypeEnum.CLINICAL)}
        >
          Add goal
        </Button>
      </Row>

      <br />
      <GridContainer>
        <>
          {processedGoals?.clinical.length === 0 && (
            <Typography
              variant="h6"
              color="text.secondary"
              textAlign={"center"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              height={"50px"}
            >
              No clinical goals found
            </Typography>
          )}
          {processedGoals?.clinical?.map((goal) => {
            return (
              <RenderGoal
                key={goal.goal_id}
                goal={goal}
                memberId={memberId}
                goalOnly={goalOnly}
                onEditPressed={triggerEditGoal}
              />
            );
          })}
        </>
      </GridContainer>
      <br />
      {!clinicalOnly && (
        <>
          <Row>
            <Typography variant="h5" color="primary">
              MEMBER GOALS
            </Typography>
            <Button
              variant="outlined"
              onClick={() => triggerAddGoal(GoalTypeEnum.MEMBER)}
            >
              Add goal
            </Button>
          </Row>

          <br />
          <GridContainer mb={"50px"}>
            <>
              {processedGoals?.member.length === 0 && (
                <Typography
                  variant="h6"
                  color="text.secondary"
                  textAlign={"center"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"50px"}
                >
                  No member goals found
                </Typography>
              )}
              {processedGoals?.member?.map((goal) => {
                return (
                  <RenderGoal
                    key={goal.goal_id}
                    goal={goal}
                    memberId={memberId}
                    goalOnly={goalOnly}
                    onEditPressed={triggerEditGoal}
                  />
                );
              })}
            </>
          </GridContainer>
        </>
      )}

      <ErrorComponent error={error} />

      <AddGoalModal
        isOpen={addGoalModalVisible}
        onRequestClose={() => {
          setAddGoalModalVisible(false);
          setSelectedGoalForEdit(null);
          setNewGoalType(null);
        }}
        memberId={memberId}
        newGoalType={newGoalType}
        goalForEdit={selectedGoalForEdit}
      />
    </>
  );
};

export const VisitFieldClinicalGoalSummary = ({
  member,
  visit
}: {
  member: MemberType;
  visit: GetVisitResponseType;
}) => {
  const assessmentOptions = [
    {
      option: getPrettyGoalAssessmentStatus(GoalAssessmentStatusEnum.ON_TRACK)
    },
    {
      option: getPrettyGoalAssessmentStatus(GoalAssessmentStatusEnum.OFF_TRACK)
    },
    { option: getPrettyGoalAssessmentStatus(GoalAssessmentStatusEnum.NO_DATA) }
  ];

  return (
    <>
      <VisitFieldGoalManagement
        memberId={member?.patient?.patient_id}
        condensed={true}
        goalOnly={true}
        clinicalOnly={true}
      />
      {member?.care_plan?.conditions?.includes(
        CarePlanConditionsEnum.HYPERTENSION
      ) && (
        <VisitContentField
          field={{
            field_id:
              CareFlowFieldIdsEnum.PRECALL_CLINICAL_TOPICS_ASSESS_BP_READINGS,
            type: CareFlowFieldTypeEnum.RADIO,
            label:
              "How are the members readings tracking toward their hypertension goals?",
            options: assessmentOptions
          }}
          member={member}
        />
      )}
      {member?.care_plan?.conditions?.includes(
        CarePlanConditionsEnum.DIABETES
      ) && (
        <VisitContentField
          field={{
            field_id:
              CareFlowFieldIdsEnum.PRECALL_CLINICAL_TOPICS_ASSESS_GM_READINGS,
            type: CareFlowFieldTypeEnum.RADIO,
            label:
              "How are the members readings tracking toward their diabetes goals?",
            options: assessmentOptions
          }}
          member={member}
        />
      )}
    </>
  );
};
