import { isFalsy } from "../helpers/helpers";
import MemberStatusEnum from "./MemberStatusEnum";
import MemberStatusReasonEnum from "./MemberStatusReasonEnum";

enum RolesEnum {
  // Not Medical Staff
  NPS = "NPS",
  NPS_MANAGER = "NPS_MANAGER",
  ADMIN = "ADMIN",
  RCM_ADMIN = "RCM_ADMIN",
  RETENTION_SPECIALIST = "RETENTION_SPECIALIST",
  TECHNICAL_SUPPORT = "TECHNICAL_SUPPORT",
  SYSTEM = "SYSTEM",

  // Medical Staff
  TH_NURSE = "TH_NURSE",
  NP_NURSE = "NP_NURSE",
  MD_PROVIDER = "MD_PROVIDER",
  THN_MANAGER = "THN_MANAGER",
  NPN_MANAGER = "NPN_MANAGER",
  NURSE_DIRECTOR = "NURSE_DIRECTOR",
  SALES_DIRECTOR = "SALES_DIRECTOR",
  NURSE_PROVIDER = "NURSE_PROVIDER",
  PROVIDER_MANAGER = "PROVIDER_MANAGER",
  MEMBER_CARE_SPECIALIST = "MEMBER_CARE_SPECIALIST"
}

export const MEDICAL_STAFF = [
  RolesEnum.TH_NURSE,
  RolesEnum.NP_NURSE,
  RolesEnum.MD_PROVIDER,
  RolesEnum.THN_MANAGER,
  RolesEnum.NPN_MANAGER,
  RolesEnum.NURSE_DIRECTOR,
  RolesEnum.ADMIN,
  RolesEnum.NURSE_PROVIDER,
  RolesEnum.PROVIDER_MANAGER,
  RolesEnum.MEMBER_CARE_SPECIALIST
];

export const ONGOING_NURSE_STAFF = [
  RolesEnum.TH_NURSE,
  RolesEnum.THN_MANAGER,
  RolesEnum.NURSE_DIRECTOR,
  RolesEnum.MEMBER_CARE_SPECIALIST
];

/**
 * These roles will show up in the nurse schedule list. User must only have roles that match this list.
 * ENG-5190, ENG-7449
 */
const ALLOWED_ROLES_FOR_NURSE_SCHEDULE_LIST = [
  RolesEnum.TH_NURSE,
  RolesEnum.THN_MANAGER,
  RolesEnum.NP_NURSE,
  RolesEnum.MEMBER_CARE_SPECIALIST
];

export function areRolesAllowedForNurseScheduleList(roles: RolesEnum[]) {
  return roles.every((role) =>
    ALLOWED_ROLES_FOR_NURSE_SCHEDULE_LIST.includes(role)
  );
}

// look up table
const roleLabels: Record<RolesEnum, string> = {
  [RolesEnum.NPS]: "New Member Specialist",
  [RolesEnum.NPS_MANAGER]: "NMS Manager",
  [RolesEnum.ADMIN]: "Admin",
  [RolesEnum.RCM_ADMIN]: "RCM",
  [RolesEnum.RETENTION_SPECIALIST]: "Retention Specialist",
  [RolesEnum.TECHNICAL_SUPPORT]: "Technical Support",
  [RolesEnum.SYSTEM]: "System",
  [RolesEnum.TH_NURSE]: "Telehealth Nurse",
  [RolesEnum.NP_NURSE]: "Intake Nurse",
  [RolesEnum.MD_PROVIDER]: "Medical Provider",
  [RolesEnum.THN_MANAGER]: "Telehealth Nurse Manager",
  [RolesEnum.NPN_MANAGER]: "Intake Nurse Manager",
  [RolesEnum.NURSE_DIRECTOR]: "Nurse Director",
  [RolesEnum.SALES_DIRECTOR]: "Sales Director",
  [RolesEnum.NURSE_PROVIDER]: "Nurse Provider",
  [RolesEnum.PROVIDER_MANAGER]: "Provider Manager",
  [RolesEnum.MEMBER_CARE_SPECIALIST]: "Member Care Specialist"
};

// function to get the value
export const getRoleLabel = (role: RolesEnum): string => {
  return roleLabels[role] || "Not defined";
};

export const StaffRoles = Object.keys(RolesEnum)
  .filter((roleItem) => roleItem !== RolesEnum.SYSTEM)
  .sort((a, b) => a.localeCompare(b)) as RolesEnum[];

export const createUserFormRoles = (role: RolesEnum) => {
  const roles = Object.keys(RolesEnum)
    .filter((roleItem) => roleItem !== RolesEnum.SYSTEM)
    .sort((a, b) => RolesEnum[a].localeCompare(RolesEnum[b]));
  if (role === RolesEnum.ADMIN) return roles;
  else return roles.filter((roleItem) => roleItem !== RolesEnum.ADMIN);
};
export const canSeeMemberDetails = (role: RolesEnum) => {
  return true;
};

export const canSeeSupportOrderForm = (role: RolesEnum) => {
  return [
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.ADMIN,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const canSeeVendorReplacementForm = (role: RolesEnum) => {
  return [RolesEnum.TECHNICAL_SUPPORT, RolesEnum.ADMIN].includes(role);
};

export const canOrderSwag = (role: RolesEnum) => {
  return [RolesEnum.ADMIN].includes(role);
};

export const canOrderMemberDevices = (role: RolesEnum) => {
  return [
    RolesEnum.MD_PROVIDER,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.ADMIN,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.MEMBER_CARE_SPECIALIST,
    RolesEnum.THN_MANAGER
  ].includes(role);
};

export const canOrderBloodPressureOrGlucose = (
  role: RolesEnum,
  isMemberMigrated: boolean
) => {
  const roles = [
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.ADMIN,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ];

  if (isMemberMigrated) {
    roles.push(RolesEnum.THN_MANAGER);
  }

  return roles.includes(role);
};

export const canSeeTasks = (role: RolesEnum) => {
  const roles = [
    RolesEnum.TH_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.RCM_ADMIN,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.NPN_MANAGER,
    RolesEnum.ADMIN,
    RolesEnum.NPS_MANAGER
  ];
  return roles.includes(role);
};

export const canBulkReassign = (role: RolesEnum) => {
  return [
    RolesEnum.NPN_MANAGER,
    RolesEnum.NPS_MANAGER,
    RolesEnum.THN_MANAGER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.ADMIN
  ].includes(role);
};

export const canSeeBillingTasks = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.RCM_ADMIN,
    RolesEnum.PROVIDER_MANAGER
  ].includes(role);
};

export const canUpdateProviderAssignment = (role: RolesEnum) => {
  return [
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.MD_PROVIDER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.ADMIN,
    RolesEnum.RCM_ADMIN
  ].includes(role);
};

export const canUpdateNurseAssignment = (role: RolesEnum) => {
  return [
    RolesEnum.NP_NURSE,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.NPN_MANAGER,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const canEditUserDetails = (role: RolesEnum) => {
  return [RolesEnum.ADMIN, RolesEnum.TECHNICAL_SUPPORT].includes(role);
};

export const canEditOrderStatus = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const canEditMemberDetails = (role: RolesEnum) => {
  return [
    ...MEDICAL_STAFF,
    RolesEnum.ADMIN,
    RolesEnum.RCM_ADMIN,
    RolesEnum.NPS,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const canDownloadChart = (role: RolesEnum) => {
  return (
    [...MEDICAL_STAFF, RolesEnum.ADMIN, RolesEnum.RCM_ADMIN]
      // remove NP_NURSE from the list
      .filter((role) => role !== RolesEnum.NP_NURSE)
      .includes(role)
  );
};

export const canStartIntake = (role: RolesEnum) => {
  return [RolesEnum.NP_NURSE, RolesEnum.NPN_MANAGER].includes(role);
};

export const canStartOnboardingExam = (role: RolesEnum) => {
  return [RolesEnum.NURSE_PROVIDER, RolesEnum.MD_PROVIDER].includes(role);
};

export const getCancelMemberStatusByRole = (
  role: RolesEnum,
  currentStatus: MemberStatusEnum = MemberStatusEnum.ACTIVE
) => {
  if (
    [RolesEnum.ADMIN, RolesEnum.TECHNICAL_SUPPORT].includes(role) ||
    ([
      RolesEnum.MEMBER_CARE_SPECIALIST,
      RolesEnum.RETENTION_SPECIALIST,
      RolesEnum.RCM_ADMIN
    ].includes(role) &&
      currentStatus === MemberStatusEnum.CANCELLATION_REQUESTED)
  )
    return {
      status: MemberStatusEnum.CANCELED,
      status_reason: MemberStatusReasonEnum.CANCELED_RETENTION,
      success_text: "Successfully processed cancellation request."
    };
  else if (
    [
      ...MEDICAL_STAFF,
      RolesEnum.RETENTION_SPECIALIST,
      RolesEnum.RCM_ADMIN,
      RolesEnum.NPS,
      RolesEnum.NPS_MANAGER
    ].includes(role)
  )
    return {
      status: MemberStatusEnum.CANCELLATION_REQUESTED,
      status_reason: MemberStatusReasonEnum.PATIENT_CANCELED,
      success_text: "Cancellation request saved and sent to Retention."
    };
  else return {};
};

/*export const canPerformTwilioCalls = (role: RolesEnum) => {
  if (EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV != "dev") return false;
  return MEDICAL_STAFF.includes(role);
};*/

export const canCallMember = (role: RolesEnum) => {
  return [
    RolesEnum.TH_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const showsViewOrdersOnSidebarMenu = (role: RolesEnum) => {
  return [RolesEnum.RETENTION_SPECIALIST, RolesEnum.NP_NURSE].includes(role);
};

export const canSeeMemberOrders = (role: RolesEnum) => {
  return [
    ...MEDICAL_STAFF,
    RolesEnum.ADMIN,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.RCM_ADMIN,
    RolesEnum.TECHNICAL_SUPPORT
  ].includes(role);
};

export const hasToHideRefillMemberSupplies = (role: RolesEnum) => {
  return [
    RolesEnum.NP_NURSE,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.RCM_ADMIN
  ].includes(role);
};

export const ProviderRoles = [
  RolesEnum.MD_PROVIDER,
  RolesEnum.NURSE_PROVIDER,
  RolesEnum.PROVIDER_MANAGER
];

export const hasProviderRole = (roles: RolesEnum[] | string[]) => {
  return roles?.some((role) => ProviderRoles.includes(role));
};

export const hasTnRole = (roles: RolesEnum[] | string[]) => {
  return roles?.some((role) => TH_Roles.includes(role));
};

export const hasIntakeNurseRole = (roles: RolesEnum[] | string[]) => {
  return roles?.some((role) => NP_Roles.includes(role));
};

export const NurseRoles = [
  RolesEnum.TH_NURSE,
  RolesEnum.NP_NURSE,
  RolesEnum.MEMBER_CARE_SPECIALIST,
  RolesEnum.THN_MANAGER,
  RolesEnum.NPN_MANAGER,
  RolesEnum.NURSE_DIRECTOR
];

export const hasNurseRole = (roles: RolesEnum[]) => {
  return roles?.some((role) => NurseRoles.includes(role));
};

export const TH_Roles = [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER];
export const NP_Roles = [RolesEnum.NP_NURSE, RolesEnum.NPN_MANAGER];

export const canViewPlatformRoles = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.RETENTION_SPECIALIST
  ].includes(role);
};

export const canEditRelationshipNotes = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT,
    ...MEDICAL_STAFF
  ].includes(role);
};

export const canCreatePastEncounter = (role: RolesEnum) => {
  return [
    RolesEnum.THN_MANAGER,
    RolesEnum.NPN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN,
    RolesEnum.PROVIDER_MANAGER
  ].includes(role);
};

/**
 * This function is used to determine if the user can edit TN encounters
 * for the past 10 days or the start of the current month, whichever is sooner
 * ENG-7567
 */
export const canEditTnEncounters = (role: RolesEnum) => {
  return [
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN
  ].includes(role);
};

export const canEditIntakeEncounters = (role: RolesEnum) => {
  return [
    RolesEnum.THN_MANAGER,
    RolesEnum.NPN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN
  ].includes(role);
};

export const canBypassRefill = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.THN_MANAGER
  ].includes(role);
};

export const canReportDevice = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const isProviderRole = (role: RolesEnum) => {
  return ProviderRoles.includes(role);
};

export const isTnRole = (role: RolesEnum) => {
  return TH_Roles.includes(role);
};

export const isNurseRole = (role: RolesEnum) => {
  return NurseRoles.includes(role);
};

export const isIntakeNurseRole = (role: RolesEnum) => {
  return NP_Roles.includes(role);
};

export const showsSurveyModalAfterCanceledContact = (role: RolesEnum) => {
  return [RolesEnum.ADMIN, RolesEnum.RETENTION_SPECIALIST].includes(role);
};

export const showsCancellationRequestHistoryButton = (role: RolesEnum) => {
  return [
    ...ONGOING_NURSE_STAFF,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.ADMIN,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.RCM_ADMIN,
    RolesEnum.NPN_MANAGER,
    RolesEnum.NP_NURSE,
    RolesEnum.NPS,
    RolesEnum.NPS_MANAGER
  ].includes(role);
};

export const canStartEncounter = (role: RolesEnum) => {
  const roles = [
    RolesEnum.TH_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER
  ];

  return roles.includes(role);
};

export const canSubmitEncounters = (role: RolesEnum) => {
  return [
    RolesEnum.TH_NURSE,
    RolesEnum.NP_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.NPN_MANAGER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.PROVIDER_MANAGER
  ].includes(role);
};

export const canSeeEncountersConnectedDropdown = (role: RolesEnum) => {
  return [
    RolesEnum.TH_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR
  ].includes(role);
};

export const canCallTwilioVideo = (role: RolesEnum) => {
  if (
    [
      RolesEnum.NURSE_PROVIDER,
      RolesEnum.MD_PROVIDER,
      RolesEnum.PROVIDER_MANAGER,
      RolesEnum.MEMBER_CARE_SPECIALIST,
      RolesEnum.ADMIN
    ].includes(role)
  ) {
    return true;
  } else {
    return false;
  }
};

export const canUpdatePatientStatus = (role: RolesEnum) => {
  return [
    RolesEnum.NP_NURSE,
    RolesEnum.NPN_MANAGER,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.ADMIN,
    RolesEnum.NPS,
    RolesEnum.NPS_MANAGER,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const canUpdateStaffTeam = (role: RolesEnum) => {
  return [
    RolesEnum.THN_MANAGER,
    RolesEnum.NPN_MANAGER,
    RolesEnum.NPS_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.SALES_DIRECTOR,
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT
  ].includes(role);
};

export const canSeeMemberDetailsOverview = (role: RolesEnum) => {
  return [
    RolesEnum.SALES_DIRECTOR,
    RolesEnum.NPS_MANAGER,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.RCM_ADMIN
  ].includes(role);
};

export const canSeeEncounters = (role: RolesEnum) => {
  return ![
    RolesEnum.NPS,
    RolesEnum.NPS_MANAGER,
    RolesEnum.SALES_DIRECTOR
  ].includes(role);
};

export const canSeeReadings = (role: RolesEnum) => {
  return [...MEDICAL_STAFF, RolesEnum.RCM_ADMIN].includes(role);
};

export const isDeleteNurseAllowed = (role: RolesEnum) => {
  // matching OPA https://github.com/CopilotIQ/authz-policies/blob/d779779990adbd47187cb9f315af438739548df6/src/main/rego/carerspatients.rego#L9-L33
  return [
    RolesEnum.NP_NURSE,
    RolesEnum.NPN_MANAGER,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER
  ].includes(role);
};

export const isDeleteProviderAllowed = (role: RolesEnum) => {
  // matching OPA https://github.com/CopilotIQ/authz-policies/blob/d779779990adbd47187cb9f315af438739548df6/src/main/rego/carerspatients.rego#L9-L33
  return [
    RolesEnum.NP_NURSE,
    RolesEnum.NPN_MANAGER,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER
  ].includes(role);
};

export const canScheduleProviders = (role: RolesEnum) => {
  return [
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.THN_MANAGER,
    RolesEnum.NP_NURSE,
    RolesEnum.TH_NURSE,
    RolesEnum.NPN_MANAGER,
    RolesEnum.NP_NURSE,
    RolesEnum.RCM_ADMIN,
    RolesEnum.NPN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const canScheduleNurses = (role: RolesEnum) => {
  return [
    RolesEnum.TH_NURSE,
    RolesEnum.NP_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.NPN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.MEMBER_CARE_SPECIALIST,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.TECHNICAL_SUPPORT
  ].includes(role);
};

export const canUpdateOutcomeNurseAppointments = (role: RolesEnum) => {
  return [
    RolesEnum.TH_NURSE,
    RolesEnum.NP_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.NPN_MANAGER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN
  ].includes(role);
};

export const canUpdateOutcomeProviderAppointments = (role: RolesEnum) => {
  return [
    RolesEnum.MD_PROVIDER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.THN_MANAGER,
    RolesEnum.NPN_MANAGER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN
  ].includes(role);
};

export const canSeeEncounterProviderDetails = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.RCM_ADMIN,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER
  ].includes(role);
};

export const canUpdateMemberTimePreferences = (role: RolesEnum) => {
  return ![RolesEnum.RCM_ADMIN, RolesEnum.TECHNICAL_SUPPORT].includes(role);
};

export const hasFourBusinessDaySchedulingRestriction = (role: RolesEnum) => {
  return [RolesEnum.NP_NURSE, RolesEnum.NPN_MANAGER].includes(role);
};

export const hasStateLicensure = (roles: RolesEnum[]) => {
  if (roles === undefined) return;
  const supportedRoles = [
    RolesEnum.TH_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.ADMIN
  ];

  return roles.some((role) => supportedRoles.includes(role));
};

export const canSeeAssignmentsOnStaffProfile = (
  roles: RolesEnum[],
  currentRole: RolesEnum
) => {
  if (roles === undefined) return;
  const userSupportedRoles = [
    RolesEnum.TH_NURSE,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER
  ];

  const authenticatedUserSupportedRoles = [
    ...MEDICAL_STAFF,
    RolesEnum.ADMIN,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.RCM_ADMIN,
    RolesEnum.TECHNICAL_SUPPORT
  ];
  return (
    roles.some((role) => userSupportedRoles.includes(role)) &&
    authenticatedUserSupportedRoles.includes(currentRole)
  );
};

export const canSeeScheduleOnStaffProfile = (role: RolesEnum) => {
  const roles = [
    RolesEnum.TH_NURSE,
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.MEMBER_CARE_SPECIALIST,
    RolesEnum.NP_NURSE,
    RolesEnum.NPN_MANAGER,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.RCM_ADMIN,
    RolesEnum.ADMIN,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER
  ];

  return roles.includes(role);
};

export const staffMemberCanHaveSchedule = (roles: RolesEnum[]) => {
  if (roles === undefined) return;
  const supportedRoles = [
    RolesEnum.TH_NURSE,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER
  ];

  return roles.some((role) => supportedRoles.includes(role));
};

export const canSeeEncountersOnStaffDetailsCurrentRole = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.NPN_MANAGER,
    RolesEnum.THN_MANAGER,
    RolesEnum.TH_NURSE,
    RolesEnum.NP_NURSE,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.RCM_ADMIN
  ].includes(role);
};

export const staffMemberRoleCanSubmitEncounters = (roles: RolesEnum[]) => {
  if (isFalsy(roles)) return false;
  const supportedRoles = [
    RolesEnum.THN_MANAGER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.NP_NURSE,
    RolesEnum.TH_NURSE,
    RolesEnum.NURSE_PROVIDER
  ];
  return roles.some((role) => supportedRoles.includes(role));
};

// This mobile number function also updates Zoom Info number.
export const canUpdateMobileNumber = (role: RolesEnum) => {
  return [RolesEnum.ADMIN, RolesEnum.TECHNICAL_SUPPORT].includes(role);
};

export const userCanAllowDisallowScheduling = (role: RolesEnum) => {
  if (role === undefined) return;
  const supportedRoles = [
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.THN_MANAGER,
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT
  ];
  return supportedRoles.includes(role);
};

export const userCanBeScheduled = (roles: RolesEnum[]) => {
  // add more conditions here if needed
  return roles.includes(RolesEnum.TH_NURSE);
};

export const staffMemberCanHaveOrders = (roles: RolesEnum[]) => {
  if (roles === undefined) return;
  const notSupportedRoles = [
    RolesEnum.RCM_ADMIN,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.NPS,
    RolesEnum.NPS_MANAGER,
    RolesEnum.SALES_DIRECTOR
  ];
  return roles.some((role) => !notSupportedRoles.includes(role));
};

export const canSeeOrdersOnStaffDetailsCurrentRole = (role: RolesEnum) => {
  return [
    RolesEnum.RCM_ADMIN,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.ADMIN
  ].includes(role);
};

export const canSeeStaffDetails = (role: RolesEnum) => {
  return ![
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.NPS,
    RolesEnum.NPS_MANAGER,
    RolesEnum.SALES_DIRECTOR
  ].includes(role);
};

export const canEditDeleteTimeOff = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.THN_MANAGER,
    RolesEnum.TECHNICAL_SUPPORT
  ].includes(role);
};

export const canHaveTimeOff = (roles: RolesEnum[]) => {
  if (isFalsy(roles)) return;
  const supportedRoles = [RolesEnum.TH_NURSE];
  return roles?.some((role) => supportedRoles.includes(role));
};

export const canEditProviderBlockedTime = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.TECHNICAL_SUPPORT
  ].includes(role);
};

export const canHaveProviderBlockedTime = (roles: RolesEnum[]) => {
  if (isFalsy(roles)) return;
  const supportedRoles = [RolesEnum.MD_PROVIDER, RolesEnum.NURSE_PROVIDER];
  return roles?.some((role) => supportedRoles.includes(role));
};

export const canSeeTimer = (role: RolesEnum) => {
  return MEDICAL_STAFF.includes(role);
};

export const canSetStaffInactive = (
  currentRole: RolesEnum,
  targetRoles: RolesEnum[]
) => {
  const userIsAdmin = [RolesEnum.ADMIN].includes(currentRole);
  const targetIsAdmin = targetRoles.includes(RolesEnum.ADMIN);

  return userIsAdmin || !targetIsAdmin;
};

export const canOnboardMemberStatus = (status: MemberStatusEnum) => {
  return [
    MemberStatusEnum.CANCELED,
    MemberStatusEnum.AUTO_CANCELED,
    MemberStatusEnum.CANCELLATION_REQUESTED,
    MemberStatusEnum.REEVALUATING_PATIENT,
    MemberStatusEnum.QUALIFIED
  ].includes(status);
};

export const canUpdateMemberStatus = (
  status: MemberStatusEnum,
  role: RolesEnum
) => {
  const allowedStatuses = [
    MemberStatusEnum.CANCELED,
    MemberStatusEnum.AUTO_CANCELED,
    MemberStatusEnum.CANCELLATION_REQUESTED,
    MemberStatusEnum.INACTIVE,
    MemberStatusEnum.NEVER_ENGAGED
  ];
  const allowedRoles = [
    RolesEnum.THN_MANAGER,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.NP_NURSE,
    RolesEnum.NPN_MANAGER,
    RolesEnum.ADMIN,
    RolesEnum.MEMBER_CARE_SPECIALIST,
    RolesEnum.RETENTION_SPECIALIST,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.NPS,
    RolesEnum.NPS_MANAGER,
    RolesEnum.SALES_DIRECTOR
  ];
  return allowedRoles.includes(role) && allowedStatuses.includes(status);
};

export const canSeeTechSupportProfileTab = (role: RolesEnum) => {
  return [RolesEnum.ADMIN, RolesEnum.TECHNICAL_SUPPORT].includes(role);
};

export const canSeeTeamsOnProfileInfo = (roles: RolesEnum[]) => {
  return roles.some((role) =>
    [
      RolesEnum.TH_NURSE,
      RolesEnum.NP_NURSE,
      RolesEnum.MEMBER_CARE_SPECIALIST,
      RolesEnum.NURSE_PROVIDER,
      RolesEnum.MD_PROVIDER,
      RolesEnum.THN_MANAGER,
      RolesEnum.NPN_MANAGER
    ].includes(role)
  );
};

export const canSeeScheduleTypeOnProfileInfo = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.PROVIDER_MANAGER,
    RolesEnum.MD_PROVIDER,
    RolesEnum.NURSE_PROVIDER,
    RolesEnum.TECHNICAL_SUPPORT
  ].includes(role);
};

export const canEditScheduleTypeOnProfileInfo = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.PROVIDER_MANAGER
  ].includes(role);
};

export const userCanHaveScheduleType = (roles: RolesEnum[]) => {
  return roles.some((role) =>
    [RolesEnum.MD_PROVIDER, RolesEnum.NURSE_PROVIDER].includes(role)
  );
};

export const canFilterReadingsByDevices = (role: RolesEnum) => {
  return [RolesEnum.ADMIN, RolesEnum.TECHNICAL_SUPPORT].includes(role);
};

export const canSearchMemberByDevice = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    RolesEnum.TECHNICAL_SUPPORT,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export const canSeeCareFlow = (role: RolesEnum) => {
  return [
    RolesEnum.ADMIN,
    ...TH_Roles,
    RolesEnum.NURSE_DIRECTOR,
    RolesEnum.MEMBER_CARE_SPECIALIST
  ].includes(role);
};

export default RolesEnum;
